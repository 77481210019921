import {IModType} from "@backend/enums";

export enum localStorageKeys {
    SubmitWhenEnterKeyIsPressed = 'submitWhenEnterKeyIsPressed',
    VoiceGender = 'voiceGender',
    UserName = 'userName'
}

export enum Paths {
    Home = '/',
    ImportWords = '/import-words',
    ImportBook = '/import-book',
    AllBooks = '/all-books',
    // UnavailableChapters = '/unavailable-chapters',
}

export enum TextInputColor {
    NEUTRAL = 'neutral',
    DANGER = 'danger'
}

export const ModTypes: { [K in keyof Required<IModType>]: K } = {
    book: 'book',
    chapter: 'chapter',
    spelling: 'spelling',
    none: 'none'
};