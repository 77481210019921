import React from 'react';
import Typography from '@mui/joy/Typography';

interface Props {
    currentIndex: number,
    totalCount: number,
    description?: String
}

export const ActivityFooter: React.FC<Props> = ({currentIndex, totalCount, description}) => {
    return (
        <>
            <div>
                <Typography level="h1" fontWeight="lg" fontSize="xl" padding="10px" bgcolor="blue"
                            sx={{
                                color: 'white',
                                display: "flex",
                                width: "100%",
                                boxSizing: "border-box",
                                justifyContent: 'center',
                                height: '5vh'
                            }}>
                    <Typography>
                        {description ? `${description}: ` : ''} {currentIndex + 1 < totalCount ? currentIndex + 1 : totalCount} of {totalCount}{}
                    </Typography>
                </Typography>
            </div>
        </>
    )
}