import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "src/Home";
import ImportWords from "src/components/Spelling/ImportWords";
import {Paths} from "src/utils/constants";
import {AssessmentHomePage} from "src/components/Spelling/AssessmentHomePage";
import {StudyWords} from "src/components/Spelling/StudyWords";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {ChooseCorrectAnswerInChapter} from "src/components/Chapter/ChooseCorrectAnswerInChapter";
import ImportBook from "src/components/Chapter/ImportBook";
import AllBooks from "src/components/Chapter/AllBooks";
import {SelectChapterToStudy} from "src/components/Chapter/SelectChapterToStudy";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path={Paths.Home} element={<Home/>}/>
                <Route path={Paths.ImportBook} element={<ImportBook/>}/>
                <Route path={Paths.ImportWords} element={<ImportWords/>}/>
                <Route path={Paths.AllBooks} element={<AllBooks/>}/>
                {/*<Route path={Paths.UnavailableChapters} element={<UnavailableChapters/>}/>*/}
                <Route path="/study/book/id/:id" element={<SelectChapterToStudy/>}/>
                <Route path="/test/book/id/:id" element={<AssessmentHomePage/>}/>
                <Route path="/study/chapter/id/:id" element={<ChooseCorrectAnswerInChapter/>}/>
                <Route path="/study/words/id/:id" element={<StudyWords/>}/>
                <Route path="/test/id/:id" element={<AssessmentHomePage/>}/>
                <Route path="*" element={<ModOrPageNotFound notFoundType={NotFoundType.Page}/>}/>
            </Routes>
        </Router>
    )
};

export default App;