import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {localStorageKeys} from "src/utils/constants";

export interface SettingsState {
    setFocusOnUsername: boolean,
    submitWhenEnterKeyIsPressed: string,
    userName: string
}

const initialState: SettingsState = {
    setFocusOnUsername: false,
    submitWhenEnterKeyIsPressed: ((): string => {
        if (localStorage.getItem(localStorageKeys.SubmitWhenEnterKeyIsPressed)) {
            return <string>localStorage.getItem(localStorageKeys.SubmitWhenEnterKeyIsPressed)
        }

        return 'no'
    })(),
    userName: ((): string => {
        if (localStorage.getItem(localStorageKeys.UserName)) {
            return <string>localStorage.getItem(localStorageKeys.UserName)
        }

        return ''
    })()
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        saveSubmitWhenEnterKeyIsPressed(state, action: PayloadAction<string>) {
            state.submitWhenEnterKeyIsPressed = action.payload;
            localStorage.setItem(localStorageKeys.SubmitWhenEnterKeyIsPressed, action.payload.toString());
        },
        saveUserName(state, action: PayloadAction<string>) {
            state.userName = action.payload;
            localStorage.setItem(localStorageKeys.UserName, action.payload.toString());
        },
        setValueOfSetFocusOnUsername(state, action: PayloadAction<boolean>) {
            state.setFocusOnUsername = action.payload
        }
    }
})

export const {
    saveSubmitWhenEnterKeyIsPressed,
    saveUserName,
    setValueOfSetFocusOnUsername
} = settingsSlice.actions;

export default settingsSlice.reducer;