import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type ISpellingAssessment = {
    _id: number;
    word: string;
    misspelling?: string;
    duration: number
}

export interface SpellingAssessmentState {
    data: ISpellingAssessment[]
}

const initialState: SpellingAssessmentState = {
    data: [],
}

export const spellingAssessmentSlice = createSlice({
    name: "spellingAssessment",
    initialState,
    reducers: {
        resetSpellingAssessment(state) {
            return {
                ...state,
                data: []
            }
        },
        saveSpellingAssessment(state, action: PayloadAction<ISpellingAssessment>) {
            return {
                ...state,
                data: state.data.concat(action.payload)
            };
        }
    }
})

export const {resetSpellingAssessment, saveSpellingAssessment} = spellingAssessmentSlice.actions

export default spellingAssessmentSlice.reducer