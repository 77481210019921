import React from 'react';
import Typography from '@mui/joy/Typography';
import {Box} from "@mui/joy";
import {ModTypes} from "src/utils/constants";

interface Props {
    correct: number,
    incorrect: number,
    skipped: number,
    type: string,
    typo?: number
}

export const AssessmentResultFooter: React.FC<Props> = ({correct, incorrect, skipped, type, typo}) => {
    return (
        <>
            <div>
                <Typography level="h1" fontWeight="lg" fontSize="xl" padding="10px" bgcolor="blue"
                            sx={{
                                color: 'white',
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: 'center',
                                width: "100%",
                                boxSizing: "border-box",
                                height: '5vh'
                            }}>
                    <Box
                        sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            gap: 8,
                            justifyContent: 'center',
                            width: '100%'
                        }}
                    >
                        <Typography
                            sx={{
                                display: correct === 0 ? "none" : "block",
                            }}
                        >
                            Correct: {correct}
                        </Typography>
                        <Typography
                            sx={{
                                display: incorrect === 0 ? "none" : "block"
                            }}
                        >
                            {type === ModTypes.spelling ? 'Misspelled: ' : 'Incorrect: '}{incorrect}
                        </Typography>
                        <Typography
                            sx={{
                                display: skipped === 0 ? "none" : "block",
                            }}
                        >
                            Skipped: {skipped}
                        </Typography>
                        {typo !== undefined && (
                            <Typography
                                sx={{
                                    display: typo === 0 ? "none" : "block",
                                }}
                            >
                                Typos: {typo}
                            </Typography>
                        )}
                    </Box>
                </Typography>
            </div>
        </>
    )
}