import React, {ChangeEvent, FC, MouseEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {ChapterResult, useChapterMgr} from "src/hooks/useChapterMgr";
import shuffle from "lodash/shuffle";
import {Header} from "src/components/Header";
import {Box, Modal, ModalClose, Radio, RadioGroup, Sheet, Snackbar} from "@mui/joy";
import {setCurrentSectionID} from "src/slices/chapterSlice";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import {ActivityFooter} from "src/components/Spelling/ActivityFooter";
import {Page, update} from "src/slices/pageSlice";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Typography from "@mui/joy/Typography";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from "src/slices/sharedSlice";
import {
    IBookModChapterSectionParagraph,
} from "@backend/mongoose.gen";

import {useParams} from "react-router-dom";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import GreenTrafficLight from "src/images/green_traffic_light.png"
import YellowTrafficLight from "src/images/yellow_traffic_light.png"
import RedTrafficLight from "src/images/red_traffic_light.png"
import Description from "src/components/Shared/Description";

// This component selects the incorrect and skipped answers,
// gets the corresponding Paragraphs by ID,
// then, for each paragraph, displays a question and multiple choices.
// the user must select the correct answer.

// Paragraphs JSON
// [
//     {
//         "_id": "1",
//         "textObj": {
//             "text": "For Testing this is the number 54 answer. I like the color gray. Do you like it? Chris was born in Mobile. Beans and rice is nice."
//         },
//         "multipleChoiceArray": [
//             {
//                 "_id": "1",
//                 "question": "How old are you?",
//                 "answer": "54",
//                 "options": [
//                     "15",
//                     "9",
//                     "52"
//                 ]
//             },
//             {
//                 "_id": "2",
//                 "question": "What is your favorite color?",
//                 "answer": "grey",
//                 "options": [
//                     "blue",
//                     "reg",
//                     "green"
//                 ]
//             }
//         ],
//         "questionsAndAnswersArray": [
//             {
//                 "_id": "1",
//                 "question": "How old are you?",
//                 "answer": "54"
//             },
//             {
//                 "_id": "2",
//                 "question": "What is your favorite color?",
//                 "answer": "gray"
//             },
//             {
//                 "_id": "3",
//                 "question": "Where were you born?",
//                 "answer": "Mobile"
//             },
//             {
//                 "_id": "4",
//                 "question": "In what year were you born?",
//                 "answer": "1969"
//             },
//             {
//                 "_id": "5",
//                 "question": "What is nice?",
//                 "answer": "beans and rice"
//             }
//         ]
//     },
//     {
//         "_id": "2",
//         "textObj": {
//             "text": "New Paragraph Text about Heartfulness. The city that's the headquarters of heart is Hyderabad. 2 comes after 1."
//         },
//         "multipleChoiceArray": [
//             {
//                 "_id": "1",
//                 "question": "Where is Kanha?",
//                 "answer": "Hyderabad",
//                 "options": [
//                     "Chennai",
//                     "Charlotte",
//                     "Mumbai"
//                 ]
//             }
//         ],
//         "questionsAndAnswersArray": [
//             {
//                 "_id": "1",
//                 "question": "How do you spell 'Heart'",
//                 "answer": "heart"
//             },
//             {
//                 "_id": "2",
//                 "question": "What number comes after 1?",
//                 "answer": "2"
//             }
//         ]
//     }
// ]
export const ChooseCorrectAnswerInChapter: FC = () => {
    const {getChapterResult} = useChapterMgr();

    const sections = useAppSelector((state: RootState) => state.chapter.sections);
    const assessmentResults = useAppSelector((state: RootState) => state.chapterAssessment.data);
    const assessmentCompletedBeforeStudying = useAppSelector((state: RootState) => state.chapter.assessmentCompletedBeforeStudying)
    const currentSectionID = useAppSelector((state: RootState) => state.chapter.currentSectionID);

    const [incorrectAndSkippedResults] = useState(assessmentResults.filter(result => [ChapterResult.INCORRECT, ChapterResult.SKIPPED].includes(getChapterResult(result))))
    const [paragraphsToStudy, setParagraphsToStudy] = useState<IBookModChapterSectionParagraph[]>([])
    const [currentParagraphToStudyIndex, setCurrentParagraphToStudyIndex] = useState(-1);
    const [shuffledAnswerAndOptions, setShuffledAnswerAndOptions] = useState<string[]>([])
    const [question, setQuestion] = useState<string | undefined>('')
    const [multipleChoiceArrayCtr, setMultipleChoiceArrayCtr] = useState(-1)
    const [selectedValue, setSelectedValue] = useState('');
    const [showWrongSelectionMsg, setShowWrongSelectionMsg] = useState(false);
    const [submitButtonShouldBeDisabled, setSubmitButtonShouldBeDisabled] = useState(false)
    const [numberOfWrongAttempts, setNumberOfWrongAttempts] = useState(0)
    const [showParagraph, setShowParagraph] = useState(false)
    const [currentTrafficLight, setCurrentTrafficLight] = useState(DarkTrafficLight)
    const [nextClicked, setNextClicked] = useState(false)

    const {
        playCorrectAudio,
        playWrongAudio,
        speak,
        stopSpeaking,
    } = useSharedMgr();

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);

    const {id} = useParams();
    const appDispatch = useAppDispatch();

    useEffect(() => {
        if (assessmentCompletedBeforeStudying) {
            // Start with the first Section: sections[0]._id
            let indexOfCurrentSection = sections.findIndex(section => section._id === sections[0]._id)

            while (indexOfCurrentSection < sections.length) {
                let tempParagraphsToStudy: IBookModChapterSectionParagraph[] | undefined = sections.find(
                    section => section._id.toString() === sections[indexOfCurrentSection]?._id
                )?.paragraphs.filter(
                    item => [...new Set(incorrectAndSkippedResults.map(item => item.paragraph_id))].includes(item._id)
                ).map(item => item);

                if (tempParagraphsToStudy?.length) {
                    appDispatch(setCurrentSectionID(sections[indexOfCurrentSection]._id));
                    setParagraphsToStudy(tempParagraphsToStudy);
                    break;
                }

                indexOfCurrentSection++;
            }
        } else {
            // 'Study' has been clicked on a ModCard or a Test has been completed.
            setParagraphsToStudy(sections.find(section => section._id.toString() === sections[0]._id)!.paragraphs.map(item => item))
            appDispatch(setCurrentSectionID(sections[0]._id))
        }

        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
    }, [])

    useEffect(() => {
        if (id && sections.length > 0) {
            // A Link has been used to access this component.
            setParagraphsToStudy(sections.find(section => section._id.toString() === currentSectionID)!.paragraphs.map(item => item))
            appDispatch(update(Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
        }
    }, [sections]);

    useEffect(() => {
        if (currentParagraphToStudyIndex === -1) {
            return
        }

        if (currentParagraphToStudyIndex < paragraphsToStudy.length) {
            setMultipleChoiceArrayCtr(0)
        }
    }, [currentParagraphToStudyIndex])

    useEffect(() => {
        if (multipleChoiceArrayCtr === -1 || currentParagraphToStudyIndex === -1 || currentParagraphToStudyIndex === paragraphsToStudy.length) {
            return
        }

        if (multipleChoiceArrayCtr === paragraphsToStudy[currentParagraphToStudyIndex].multiple_choices.length) {
            // Show the next set of Multiple Choice Questions from the next Paragraph
            setMultipleChoiceArrayCtr(0)

            // Check if another section needs to be studied.
            let sectionHasParasToStudy = false
            let indexOfCurrentSection = sections.findIndex(section => section._id === currentSectionID)

            while (indexOfCurrentSection < sections.length) {
                let tempParagraphsToStudy: IBookModChapterSectionParagraph[] | undefined;

                if (assessmentCompletedBeforeStudying) {
                    tempParagraphsToStudy = sections.find(
                        section => section._id.toString() === sections[indexOfCurrentSection + 1]?._id
                    )?.paragraphs.filter(
                        item => [...new Set(incorrectAndSkippedResults.map(item => item.paragraph_id))].includes(item._id)
                    ).map(item => item);
                } else {
                    // Study session was started directly on ModCard
                    tempParagraphsToStudy = sections.find(
                        section => section._id.toString() === sections[indexOfCurrentSection + 1]?._id
                    )?.paragraphs.map(item => item);
                }

                if (tempParagraphsToStudy?.length) {
                    sectionHasParasToStudy = true;
                    appDispatch(setCurrentSectionID(sections[indexOfCurrentSection + 1]._id));
                    setParagraphsToStudy(tempParagraphsToStudy);
                    break;
                }

                indexOfCurrentSection++;
            }

            if (sectionHasParasToStudy) {
                setCurrentParagraphToStudyIndex(0)
            } else {
                setCurrentParagraphToStudyIndex(currentParagraphToStudyIndex + 1)
            }

            return
        }

        setQuestion(paragraphsToStudy[currentParagraphToStudyIndex].multiple_choices[multipleChoiceArrayCtr].question)
        setShuffledAnswerAndOptions(shuffle([paragraphsToStudy[currentParagraphToStudyIndex].multiple_choices[multipleChoiceArrayCtr].answer, ...paragraphsToStudy[currentParagraphToStudyIndex].multiple_choices[multipleChoiceArrayCtr].options]))
    }, [multipleChoiceArrayCtr]);

    useEffect(() => {
        if (question !== '') {
            stopSpeaking()
            speak(question as string)
        }
    }, [question]);

    useEffect(() => {
        if (correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.CHOOSE_CORRECT_ANSWER_IN_CHAPTER) {
            setCurrentTrafficLight(DarkTrafficLight)
            setSubmitButtonShouldBeDisabled(false)
            setSelectedValue('')
            setMultipleChoiceArrayCtr(prevState => prevState + 1)
        }

        appDispatch(resetCorrectAudioOnEnd())
    }, [correctAudioOnEndFired])

    useEffect(() => {
        const handleWrongAudioOnEnd = async () => {
            if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.CHOOSE_CORRECT_ANSWER_IN_CHAPTER) {
                if (nextClicked) {
                    setNextClicked(false)
                    setCurrentTrafficLight(DarkTrafficLight)
                    setSelectedValue('')
                    setMultipleChoiceArrayCtr(prevState => prevState + 1)
                    return
                }

                if (numberOfWrongAttempts > 1) {
                    setShowParagraph(true)
                    speak(paragraphsToStudy[currentParagraphToStudyIndex].text)
                }
            }
        }

        handleWrongAudioOnEnd().catch((error) => {
            console.error(error)
        })

        appDispatch(resetWrongAudioOnEnd())
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        if (numberOfWrongAttempts === 1) {
            setCurrentTrafficLight(YellowTrafficLight)
        } else if (numberOfWrongAttempts == 2) {
            setCurrentTrafficLight(RedTrafficLight)
        }
    }, [numberOfWrongAttempts]);

    const handleStart_Click = () => {
        setCurrentParagraphToStudyIndex(0)
    }

    const handleReadSentence_Click = () => {
        stopSpeaking()
        speak(question as string)
    }

    const handleNext_Click = () => {
        stopSpeaking()
        setCurrentTrafficLight(YellowTrafficLight)
        setNextClicked(true)
        setNumberOfWrongAttempts(0)
        playWrongAudio()
    }

    const handleSubmit_Click = () => {
        if (selectedValue === paragraphsToStudy[currentParagraphToStudyIndex].multiple_choices[multipleChoiceArrayCtr].answer) {
            setCurrentTrafficLight(GreenTrafficLight)
            setSubmitButtonShouldBeDisabled(true)
            setNumberOfWrongAttempts(0)
            playCorrectAudio()
        } else {
            if (selectedValue === '') {
                setCurrentTrafficLight(YellowTrafficLight)
            } else {
                setNumberOfWrongAttempts(prevState => prevState + 1)
            }

            setShowWrongSelectionMsg(true)
            playWrongAudio()
        }
    }

    return (
        <>
            {
                (() => {
                    return (
                        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
                            <Header/>
                            <Title title={'Choose the Correct Answer'}/>

                            <Description
                                description={sections.find(section => section._id === currentSectionID)?.title || ""}/>
                            <Body>
                                {
                                    (() => {
                                        if (currentParagraphToStudyIndex > -1 && currentParagraphToStudyIndex < paragraphsToStudy.length) {
                                            return (
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        gap: 20
                                                    }}>
                                                    <Typography fontWeight="lg" fontSize="x-large">
                                                        {question}
                                                    </Typography>
                                                    <br/>
                                                    {
                                                        <>
                                                            <RadioGroup
                                                                overlay
                                                                size="lg"
                                                                value={selectedValue}
                                                                name="radio-buttons-group"
                                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                    setSelectedValue(event.target.value)
                                                                }}
                                                                sx={{
                                                                    gap: 1
                                                                }}
                                                            >
                                                                {shuffledAnswerAndOptions.map((choice, index) => (
                                                                    <Sheet
                                                                        component="label"
                                                                        key={choice}
                                                                        variant="outlined"
                                                                        sx={{
                                                                            p: 2,
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'left',
                                                                            boxShadow: 'sm',
                                                                            borderRadius: 'lg',
                                                                            border: '1px solid',
                                                                            borderColor: 'primary.500'
                                                                        }}
                                                                    >
                                                                        <Radio key={index} value={choice} label={choice}
                                                                               variant='outlined'/>
                                                                    </Sheet>
                                                                ))}
                                                            </RadioGroup>
                                                            <br/>
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 125
                                                                }}
                                                                alt={
                                                                    'Traffic Light'
                                                                }
                                                                src={currentTrafficLight}
                                                            />
                                                        </>
                                                    }
                                                </Box>
                                            )
                                        }
                                    })()
                                }
                            </Body>
                            <ButtonRow>
                                {
                                    (() => {
                                        if (currentParagraphToStudyIndex === paragraphsToStudy.length) {
                                            return (
                                                <Button variant="solid" color="primary"
                                                        sx={resizeButtonSX()}
                                                        onClick={() => {
                                                            appDispatch(update(Page.TYPE_CORRECT_ANSWER_IN_CHAPTER))
                                                        }}>Next Activity</Button>
                                            )
                                        }

                                        if (currentParagraphToStudyIndex === -1) {
                                            return (
                                                <Button variant="solid" color="primary"
                                                        sx={resizeButtonSX()}
                                                        onClick={handleStart_Click}>Start</Button>
                                            )
                                        }

                                        if (currentParagraphToStudyIndex < paragraphsToStudy.length) {
                                            return (
                                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                                    <Button sx={resizeButtonSX()}
                                                            onClick={handleReadSentence_Click}>Read Sentence</Button>
                                                    <Button variant="solid" color="primary"
                                                            sx={resizeButtonSX()}
                                                            onClick={handleSubmit_Click}
                                                            disabled={submitButtonShouldBeDisabled}
                                                    >Submit</Button>
                                                    <Button sx={resizeButtonSX()}
                                                            onClick={handleNext_Click}>Next</Button>
                                                </ButtonGroup>
                                            )
                                        }
                                    })()
                                }
                            </ButtonRow>
                            <ActivityFooter currentIndex={currentParagraphToStudyIndex}
                                            totalCount={paragraphsToStudy.length}
                                            description='Paragraphs'/>
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color="warning"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                autoHideDuration={1750}
                                open={showWrongSelectionMsg}
                                onClose={() => {
                                    setShowWrongSelectionMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                }}
                            >
                                {
                                    selectedValue === '' ? 'Please make a selection.' : "That's not correct. Please try again."
                                }
                            </Snackbar>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    if (showParagraph) {
                        return (
                            <Modal
                                aria-labelledby="close-modal-title"
                                open={true}
                                onClose={(_event: MouseEvent<HTMLButtonElement>) => {
                                    setShowParagraph(false);
                                    setNumberOfWrongAttempts(0)
                                    setCurrentTrafficLight(DarkTrafficLight)
                                    stopSpeaking()
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        minWidth: 400,
                                        maxWidth: 600,
                                        borderRadius: 'md',
                                        p: 3,
                                        boxShadow: 'lg',
                                        overflow: 'auto',
                                        maxHeight: 500
                                    }}
                                >
                                    <ModalClose variant="plain"
                                                sx={{m: 1}}
                                    />
                                    <Typography
                                        component="h2"
                                        id="modal-title"
                                        level="h4"
                                        textColor="inherit"
                                        fontWeight="lg"
                                        mb={1}
                                        maxWidth="500px"
                                    >
                                        Find the Answer
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: '20px',
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            level="h4"
                                            color="primary"
                                        >
                                            {paragraphsToStudy[currentParagraphToStudyIndex].text}
                                        </Typography>
                                    </Box>
                                </Sheet>
                            </Modal>
                        )
                    }
                })()
            }
        </>
    )
}