import React from "react";
import Box from "@mui/material/Box";

interface ButtonRowProps {
    children: React.ReactNode,
    height?: string
}

const ButtonRow: React.FC<ButtonRowProps> = ({children, height}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: 'center',
                height: height ? height : '15vh'
            }}
        >
            {children}
        </Box>
    );
};

export default ButtonRow;
