import React, {FC} from "react";
import {Box} from "@mui/joy";
import Typography from "@mui/joy/Typography";

interface DescriptionProps {
    description: string;
}

const Description: FC<DescriptionProps> = ({description}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: 'center',
                height: '7vh'
            }}
        >
            <Typography level="h1" fontWeight="lg" fontSize="xx-large">
                {description}
            </Typography>
        </Box>
    );
};

export default Description;