import React from 'react';
import {
    Button, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Modal,
    ModalDialog
} from "@mui/joy";
import {WarningRounded} from "@mui/icons-material";

interface Props {
    showDialog: boolean,
    cancelAction: () => void,
    confirmationAction: () => void,
    assessmentPageTitle: string,
    bodyMsg: string,
    confirmBtnMsg: string
}

export const SMModalDialog: React.FC<Props> = ({
                                                   showDialog,
                                                   cancelAction,
                                                   confirmationAction,
                                                   assessmentPageTitle,
                                                   bodyMsg,
                                                   confirmBtnMsg
                                               }) => {
    return (
        <Modal
            aria-labelledby="modal-title"
            open={showDialog}
        >
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRounded/>
                    {assessmentPageTitle}
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    {bodyMsg}
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" onClick={() => confirmationAction()}>
                        {confirmBtnMsg}
                    </Button>
                    <Button variant="plain" color="neutral" onClick={() => cancelAction()}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}