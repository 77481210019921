import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Sheet, Table} from "@mui/joy";
import {getAllBooks} from "src/utils/api-service";
import Typography from "@mui/joy/Typography";
import {Paths} from "src/utils/constants";
import {useAppDispatch} from "src/app/hooks";
import {resetBookVars, setCurrentBook} from "src/slices/bookSlice";
import {IBookModSchema} from "src/zodSchemas";
import {z} from "zod";

export const AllBooks: FC = () => {
    const [allBooks, setAllBooks] = useState<z.infer<typeof IBookModSchema>[]>();

    const navigate = useNavigate();
    const appDispatch = useAppDispatch();

    const allBooksRetrieved = (bookData: z.infer<typeof IBookModSchema>[]) => {
        setAllBooks(bookData);
    };

    const retrievalFailure = (error: string) => {
        console.log('error: ', error)
    };

    useEffect(() => {
        // Reset book variables in state to prevent issue that occurs if All Books page is reloaded after a book is selected
        appDispatch(resetBookVars())

        if (process.env.REACT_APP_NODE_ENV === 'development') {
            getAllBooks(allBooksRetrieved, retrievalFailure)
            return
        }

        if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
            navigate(Paths.Home)
        }

        getAllBooks(allBooksRetrieved, retrievalFailure)
    }, [])

    const editBook = (book: z.infer<typeof IBookModSchema>) => {
        appDispatch(setCurrentBook(book))
        navigate(Paths.ImportBook)
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Typography level="h1" fontWeight="lg" fontSize="xxx-large"
                        sx={{color: "white", paddingBottom: "5px"}}>
                All Chapters
            </Typography>
            <Sheet sx={{height: '100%', overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                <Table
                    aria-label="table with sticky header"
                    stickyHeader
                    stickyFooter
                >
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Title</th>
                            <th>Grade</th>
                            <th>Year</th>
                            <th>Org</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBooks?.map((book) => (
                            <tr key={book._id!.toString()}>
                                <td>{book.subject}</td>
                                <td>{book.title}</td>
                                <td>{book.grade}</td>
                                <td>{book.academic_year}</td>
                                <td>{book.org}</td>
                                <td>
                                    <Box sx={{display: 'flex', gap: 1}}>
                                        <Button size="sm" variant="soft" color="primary"
                                                onClick={
                                                    () => editBook(book)
                                                }
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                            Count: {allBooks?.length}
                        </td>
                    </tr>
                    </tfoot>
                </Table>
            </Sheet>
        </Box>
    )
}

export default AllBooks