import React, {FC, useEffect, useRef, useState} from 'react';
import {Header} from "src/components/Header";
import {ImportOrgModHeader} from "src/components/ImportOrgModHeader";
import MultipleChoice from "src/components/Chapter/MultipleChoice";
import {Box, Button, Checkbox, ColorPaletteProp, Divider, Input, Snackbar} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import QuestionsAndAnswers from "src/components/Chapter/QuestionsAndAnswers";
import ParagraphText from "src/components/Chapter/ParagraphText";
import {highlightInvalidField} from "src/utils/functions";
import {saveBookMod, useLazyGetChapterQuery, upsertChapter} from "src/utils/api-service";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {ModTypes, Paths, TextInputColor} from "src/utils/constants";
import {BulkInsertContainer} from "src/components/Shared/BulkInsertContainer";
import {useNavigate} from "react-router-dom";
import {IBookModChapterSchema, IBookModChapterSectionSchema, IBookModSchema} from "src/zodSchemas";
import {
    addChapter,
    resetChapterID,
    setCurrentChapterID,
    setCurrentSectionID,
    setCurrentParagraphID,
    updateChapter,
    deleteChapter,
    setShowBulkDataInsertedMsg,
    setBookID,
    cloneSections,
    resetSectionID,
    addSection,
    updateSection,
    resetParagraphID,
    setChapterAvailability,
    setBookMadeUnavailableAutomatically,
    removeChapterFromChaptersThatNeedAvailabilitySaved
} from "src/slices/bookSlice";
import {setInputColor} from "src/utils/functions";
import {RichTreeView} from "@mui/x-tree-view";
import {TreeViewItem} from "src/utils/interfaces";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";
// @ts-ignore
import extractTextFromPDF, {Variant} from "pdf-parser-client-side";
import ParseAndImportPDF from "src/components/Chapter/ParseAndImportPDF";

export const ImportBook: FC = () => {
    const [importComplete, setImportComplete] = useState(false)
    const [saveAllInfoClicked, setSaveAllInfoClicked] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")
    const [chapterTitleInputColor, setChapterTitleInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [sectionTitleInputColor, setSectionTitleInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [chaptersData, setChaptersData] = useState<TreeViewItem[]>([])
    const [expandedItems, setExpandedItems] = React.useState<string[]>([])
    const [chapterTitle, setChapterTitle] = useState('')
    const [sectionTitle, setSectionTitle] = useState('')
    const [showDeleteChapterConfirmation, setShowDeleteChapterConfirmation] = useState(false)

    const chapters = useAppSelector((state: RootState) => state.book.currentBook.chapters);
    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const currentSectionID = useAppSelector((state: RootState) => state.book.currentSectionID);
    const deletedParagraphID = useAppSelector((state: RootState) => state.book.deletedParagraphID);
    const clearParagraphInfo = useAppSelector((state: RootState) => state.chapter.clearParagraphInfo);
    const showBulkInsertDataSavedMsg = useAppSelector((state: RootState) => state.book.showBulkDataInsertedSavedMsg);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);

    const sections = useAppSelector((state: RootState) => state.book.currentBook.chapters.find(chapter => chapter._id === currentChapterID)?.sections);
    const paragraphs = useAppSelector((state: RootState) => state.book.currentBook.chapters.find(chapter => chapter._id === currentChapterID)?.sections?.find(section => section._id === currentSectionID)?.paragraphs);

    const [getChapter, {data, fulfilledTimeStamp}] = useLazyGetChapterQuery();

    const appDispatch = useAppDispatch();

    const navigate = useNavigate();

    const refChapterTitle = useRef<HTMLInputElement>(null);
    const refSectionTitle = useRef<HTMLInputElement>(null);

    const bookImported = (result: any) => {
        appDispatch(setBookID(result.data.id))
        setSnackbarMsg('The Book has been imported to the DB.')
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
        setImportComplete(true)
    };

    const importFailure = (error: string) => {
        setSnackbarMsg(`Error occurred importing Data ... ${error}`)
        setSnackbarMsgColor('danger')
        setShowSnackbarMsg(true)
    };

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV !== 'development') {
            if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
                navigate(Paths.Home)
            }
        }

        // This is necessary as useEffect runs twice in Dev builds when React Strict Mode is enabled as of React version 18.
        setChaptersData([])
    }, []);

    useEffect(() => {
        if (clearParagraphInfo) {
            appDispatch(setCurrentParagraphID(''))
        }
    }, [clearParagraphInfo]);

    useEffect(() => {
        if (showBulkInsertDataSavedMsg) {
            setSnackbarMsg('Data has been inserted.')
            setSnackbarMsgColor('success')
            setShowSnackbarMsg(true)
            appDispatch(setShowBulkDataInsertedMsg(false))
        }
    }, [showBulkInsertDataSavedMsg]);

    useEffect(() => {
        if (saveAllInfoClicked) {
            const bookResult = IBookModSchema.safeParse(currentBook)

            if (!bookResult.success) {
                setSnackbarMsg('Please enter the required Info.')
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                setSaveAllInfoClicked(false)
                return
            }

            saveBookMod(currentBook, bookImported, importFailure)
            setSaveAllInfoClicked(false)
        }
    }, [saveAllInfoClicked])

    useEffect(() => {
        if (chapters?.length > 0) {
            if (chapters.length >= chaptersData.length) {
                // A Chapter, Section or Paragraph has been added
                chapters.forEach((chapter) => {
                        const existingChapter = chaptersData.find(item => item.id === chapter._id);
                        if (existingChapter) {
                            sections?.forEach(section => {
                                const existingSection = existingChapter.children?.find(child => child.id === section._id);

                                if (existingSection) {
                                    const sectionToUpdate = sections.find(section => section._id === currentSectionID)

                                    if (sectionToUpdate && existingSection.id === sectionToUpdate._id) {
                                        existingSection.label = sectionToUpdate.title
                                    }

                                    // Paragraph is being added to existing Section
                                    chapter.sections?.find(section => section._id === existingSection.id)?.paragraphs.forEach(para => {
                                        const previouslyAddedPara = existingSection.children?.find(child => child.id === para._id);

                                        if (previouslyAddedPara) {
                                            previouslyAddedPara.label = para.text
                                        } else {
                                            existingSection.children!.push({id: para._id, label: para.text})
                                            // setExpandedItems([existingSection.id])
                                        }
                                    })

                                    setChaptersData([...chaptersData])
                                } else {
                                    // Section is being added to existing chapter
                                    chapter.sections?.forEach(section => {
                                        const previouslyAddedSection = existingChapter.children?.find(child => child.id === section._id);

                                        if (previouslyAddedSection) {
                                            previouslyAddedSection.label = section.title
                                        } else {
                                            // New Section is being Saved
                                            const sectionItem: TreeViewItem = {
                                                id: section._id.toString(),
                                                label: section.title,
                                                children: []
                                            };

                                            existingChapter.children!.push(sectionItem)
                                            // setExpandedItems([existingChapter!.id])
                                        }
                                    })

                                    setChaptersData([...chaptersData])
                                }
                            })
                        } else {
                            // New Chapter is being Saved
                            const chapterItem: TreeViewItem = {
                                id: chapter._id,
                                label: chapter.title,
                                children: []
                            };

                            setChaptersData(prevState => [...prevState, chapterItem])
                        }
                    }
                )
            } else {
                // A Chapter has been deleted
                const deletedChapter = chaptersData.find(chapterData => !chapters.some(chapter => chapter._id === chapterData.id));

                if (deletedChapter) {
                    setChaptersData(chaptersData.filter(chapterData => chapterData.id !== deletedChapter.id));
                }
            }
        } else {
            setChaptersData([])
        }
    }, [chapters, sections, paragraphs])

    useEffect(() => {
        if (currentChapterID !== '') {
            setChapterTitle(chapters.find(chapter => chapter!._id === currentChapterID)!.title)
        }
    }, [currentChapterID]);

    useEffect(() => {
        if (currentChapterID !== '' && currentSectionID !== '') {
            let titleToUpdate = chapters.find(chapter => chapter?._id === currentChapterID)?.sections.find(section => section?._id === currentSectionID)?.title

            if (titleToUpdate) {
                setSectionTitle(titleToUpdate)
            }
        }
    }, [currentSectionID]);

    useEffect(() => {
        if (deletedParagraphID !== '') {
            setChaptersData(prevState => {
                const chapterIndex = prevState.findIndex(chapter => chapter.id === currentChapterID)

                if (chapterIndex !== -1) {
                    const updatedChildren = prevState[chapterIndex].children!.filter(child => child.id !== deletedParagraphID);
                    return [...prevState.slice(0, chapterIndex), {
                        ...prevState[chapterIndex],
                        children: updatedChildren
                    }, ...prevState.slice(chapterIndex + 1)]
                }

                return prevState
            })
        }
    }, [deletedParagraphID])

    const clearChapterInfo = () => {
        setChapterTitle('')
        setSectionTitle('')
        appDispatch(resetChapterID())
        appDispatch(resetSectionID())
        appDispatch(resetParagraphID())
        refChapterTitle.current?.focus()
    }
    const clearSectionInfo = () => {
        setSectionTitle('')
        appDispatch(resetSectionID())
        refSectionTitle.current?.focus()
    }
    const addOrUpdateChapterInfo = () => {
        const result = IBookModChapterSchema.pick({title: true}).safeParse({title: chapterTitle})

        if (!result.success) {
            highlightInvalidField(result.error.flatten().fieldErrors.title, [refChapterTitle.current!], [setChapterTitleInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            return
        }

        if (currentChapterID === '') {
            appDispatch(addChapter(chapterTitle))
            setChapterTitle('')
        } else {
            appDispatch(updateChapter(chapterTitle))

            const updatedChaptersData = [...chaptersData]
            const existingChapter = updatedChaptersData.find(item => item.id === currentChapterID);

            if (existingChapter) {
                existingChapter.label = chapterTitle
                setChaptersData(updatedChaptersData)
            }
        }

        refChapterTitle.current?.focus()
    };
    const addOrUpdateSectionInfo = () => {
        const result = IBookModChapterSectionSchema.pick({title: true}).safeParse({title: sectionTitle})

        if (!result.success) {
            highlightInvalidField(result.error.flatten().fieldErrors.title, [refSectionTitle.current!], [setSectionTitleInputColor], setSnackbarMsg, setShowSnackbarMsg, setSnackbarMsgColor)
            return
        }

        if (currentSectionID === '') {
            appDispatch(addSection(sectionTitle))
        } else {
            appDispatch(updateSection(sectionTitle))
        }

        refSectionTitle.current?.focus()
    };

    const chapterImportedOrUpdated = (_: any) => {
        setSnackbarMsg('The Chapter has been upserted in the DB.')
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
        setImportComplete(true)
        appDispatch(removeChapterFromChaptersThatNeedAvailabilitySaved())

        // If all Chapters in a Book are unavailable, make the Book unavailable also.
        if (currentBook.chapters.filter(chapter => chapter.available)!.length === 0) {
            if (currentBook.available) {
                appDispatch(setBookMadeUnavailableAutomatically(true))
            }
        }
    };

    const upsertChapterInfoInDB = () => {
        if (currentChapterID === '') {
            setSnackbarMsg("You must add a Chapter before it can be saved.")
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        // Validate that at least one Paragraph with at least one MCQ and at least one Q and A has been added
        const chapterToSave = currentBook.chapters.find(chapter => chapter._id === currentChapterID)
        const chapterValidationResult = IBookModChapterSchema.safeParse(chapterToSave)

        if (!chapterValidationResult.success) {
            setSnackbarMsg("You must add at least one Paragraph with at least one MCQ and at least one Q and A before the Chapter can be saved.")
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        upsertChapter(currentBook, currentChapterID, chapterImportedOrUpdated, importFailure)
    };

    const saveAllInfoToDB = () => {
        setImportComplete(false)
        setSaveAllInfoClicked(true)
    };

    useEffect(() => {
        if (fulfilledTimeStamp) {
            const chapterData = data?.data!;

            appDispatch(setCurrentChapterID(chapterData._id))
            appDispatch(cloneSections(chapterData));
        }
    }, [fulfilledTimeStamp]);

    const findChapterFromSectionID = (sectionId: string): { chapterId: string | null } => {
        for (const chapter of currentBook.chapters ?? []) {
            for (const section of chapter.sections ?? []) {
                if (section._id === sectionId) {
                    return {chapterId: chapter._id};
                }
            }
        }

        return {chapterId: null};
    };

    const findSectionAndChapterFromParagraphID = (paragraphId: string): {
        chapterId: string | null;
        sectionId: string | null
    } => {
        for (const chapter of currentBook.chapters ?? []) {
            for (const section of chapter.sections ?? []) {
                for (const paragraph of section.paragraphs ?? []) {
                    if (paragraph._id === paragraphId) {
                        return {chapterId: chapter._id, sectionId: section._id};
                    }
                }
            }
        }
        return {chapterId: null, sectionId: null};
    };

    const handleItemClick = (
        _: React.SyntheticEvent,
        itemId: string
    ) => {
        const index = expandedItems.indexOf(itemId);
        let currentChapter = currentBook.chapters.find(chapter => chapter._id === itemId)

        if (currentChapter) {
            if (currentChapter.sections) {
                if (currentChapter._id !== currentChapterID) {
                    appDispatch(setCurrentChapterID(itemId))
                    appDispatch(resetSectionID())
                    appDispatch(resetParagraphID())
                    setSectionTitle('')
                }
            } else {
                getChapter(
                    {bookID: currentBook._id!.toString(), chapterID: itemId, sectionIDs: []}
                );
            }
        } else {
            const getChapterID = findChapterFromSectionID(itemId)

            if (getChapterID.chapterId) {
                appDispatch(setCurrentChapterID(getChapterID.chapterId))
                appDispatch(setCurrentSectionID(itemId))
                appDispatch(resetParagraphID())
            } else {
                const currentParagraph = currentBook.chapters.find(chapter => chapter._id === currentChapterID)?.sections?.find(section => section._id === currentSectionID)?.paragraphs?.find(paragraph => paragraph._id === itemId)

                if (currentParagraph) {
                    appDispatch(setCurrentParagraphID(itemId))
                } else {
                    const chapterID_And_SectionID = findSectionAndChapterFromParagraphID(itemId)

                    if (chapterID_And_SectionID.chapterId && chapterID_And_SectionID.sectionId) {
                        appDispatch(setCurrentChapterID(chapterID_And_SectionID.chapterId))
                        appDispatch(setCurrentSectionID(chapterID_And_SectionID.sectionId))
                        appDispatch(setCurrentParagraphID(itemId))
                    }
                }
            }
        }

        if (index !== -1) {
            let updatedExpandedItems = [...expandedItems];
            updatedExpandedItems.splice(index, 1)
            setExpandedItems(updatedExpandedItems)
        } else {
            setExpandedItems(prevState => [...prevState, itemId])
        }
    };

    const deleteChapterInfo = () => {
        appDispatch(deleteChapter())
        setChapterTitle('')
        refChapterTitle.current?.focus()
    }

    return (
        <div style={{marginBottom: '10px'}}>
            <Header/>
            <Box
                sx={{
                    marginLeft: '20px',
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: 'fit-content',
                }}
            >
                <ImportOrgModHeader
                    type={ModTypes.book}
                    importComplete={importComplete}
                    saveAllInfoClicked={saveAllInfoClicked}
                    saveInfo={() => {
                    }}
                    existingChapter={null}
                />
                <Divider orientation="vertical" sx={{bgcolor: "darkblue", marginTop: '15px'}}/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '10px',
                        overflow: 'scroll',
                        maxHeight: '49vh'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <Typography
                            level="inherit"
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Number of Chapters Added: {chapters?.length} / Current Chapter Title:
                        </Typography>
                        <Input
                            color={chapterTitleInputColor as ColorPaletteProp}
                            value={chapterTitle}
                            sx={{
                                marginLeft: '5px'
                            }}
                            slotProps={{
                                input: {
                                    ref: refChapterTitle
                                },
                            }}
                            onChange={(event) => {
                                setChapterTitle(event.target.value)
                                setInputColor(event.target.value, setChapterTitleInputColor)
                            }
                            }
                            onKeyDown={
                                (event) => {
                                    if (event.key === 'Enter' && chapterTitle.trim() !== '') {
                                        addOrUpdateChapterInfo()
                                    }
                                }
                            }
                        />
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={addOrUpdateChapterInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >{`${currentChapterID === '' ? 'Add' : 'Update'} Chapter Info`}
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={clearChapterInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >Clear Chapter Info
                            </Typography>
                        </Button>
                        <Button
                            disabled
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={() => {
                                const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

                                if (!chapterIDResult.success) {
                                    setSnackbarMsg('Please select a Chapter to delete.')
                                    setSnackbarMsgColor('danger')
                                    setShowSnackbarMsg(true)
                                    return
                                }

                                setShowDeleteChapterConfirmation(true)
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >
                                Delete Chapter
                            </Typography>
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <div style={{display: 'flex', whiteSpace: 'nowrap', alignItems: 'center'}}>
                            <Typography
                                level="inherit"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'text.primary',
                                }}
                            >
                                Chapter Available to Study?
                            </Typography>
                            &nbsp;&nbsp;
                            <Checkbox
                                checked={!!chapters.find(chapter => chapter?._id === currentChapterID)?.available}
                                onClick={() => {
                                    if (!currentChapterID)
                                        return

                                    appDispatch(setChapterAvailability(!chapters.find(chapter => chapter?._id === currentChapterID)?.available))
                                }
                                }
                            />
                            &nbsp;&nbsp;
                        </div>
                        <Divider
                            orientation="vertical"
                            style={{backgroundColor: 'red', width: '3px', marginTop: '5px', marginBottom: '5px'}}
                        />
                        &nbsp;&nbsp;
                        <Typography
                            level="inherit"
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Current Section Title:
                        </Typography>
                        <Input
                            color={sectionTitleInputColor as ColorPaletteProp}
                            value={sectionTitle}
                            sx={{
                                marginLeft: '5px'
                            }}
                            slotProps={{
                                input: {
                                    ref: refSectionTitle
                                },
                            }}
                            onChange={(event) => {
                                setSectionTitle(event.target.value)
                                setInputColor(event.target.value, setSectionTitleInputColor)
                            }
                            }
                            onKeyDown={
                                (event) => {
                                    if (event.key === 'Enter' && sectionTitle.trim() !== '') {
                                        addOrUpdateSectionInfo()
                                    }
                                }
                            }
                        />
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={addOrUpdateSectionInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >{`${currentSectionID === '' ? 'Add' : 'Update'} Section Info`}
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={clearSectionInfo}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >Clear Section Info
                            </Typography>
                        </Button>
                        <Button
                            disabled
                            sx={{
                                marginLeft: "10px",
                            }}
                            onClick={() => {
                                const sectionIDResult = IBookModChapterSectionSchema.pick({_id: true}).safeParse({_id: currentChapterID})

                                if (!sectionIDResult.success) {
                                    setSnackbarMsg('Please select a Section to delete.')
                                    setSnackbarMsgColor('danger')
                                    setShowSnackbarMsg(true)
                                    return
                                }

                                setShowDeleteChapterConfirmation(true)
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white'
                                }}
                            >
                                Delete Section
                            </Typography>
                        </Button>
                    </Box>
                    <RichTreeView
                        items={chaptersData}
                        expandedItems={expandedItems}
                        onItemClick={handleItemClick}
                    />
                </Box>
            </Box>
            <ParagraphText/>
            <ParseAndImportPDF/>
            <BulkInsertContainer/>
            <MultipleChoice/>
            <QuestionsAndAnswers/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: 10
                }}
            >
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        display: currentBook._id === undefined ? 'none' : null,
                        marginTop: "10px"
                    }}
                    onClick={upsertChapterInfoInDB}
                >
                    <Typography
                        fontSize="large"
                        sx={{
                            color: 'white'
                        }}
                    >
                        Upsert Chapter Info in DB
                    </Typography>
                </Button>
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        marginTop: "10px",
                        display: currentBook._id === undefined ? null : 'none'
                    }}
                    onClick={saveAllInfoToDB}
                >
                    <Typography
                        fontSize="large"
                        sx={{
                            color: 'white'
                        }}
                    >
                        Save Book to DB
                    </Typography>
                </Button>
                {
                    (() => {
                        if (showDeleteChapterConfirmation)
                            return deleteConfirmationDialog('Chapter', setShowDeleteChapterConfirmation, deleteChapterInfo)
                    })()
                }
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </div>
    )
}

export default ImportBook