import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {z} from "zod";
import {ModTypes} from "src/utils/constants";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import {
    IBookModChapterSectionParagraphMultipleChoiceSchema, IBookModChapterSectionParagraphQuestionsAndAnswerSchema,
    IBookModChapterSectionParagraphSchema, IBookModChapterSchema,
    IBookModSchema
} from "src/zodSchemas";
import {newObjectId} from "src/utils/functions";
import {IOrgModInfoSchema} from "src/components/ImportOrgModHeader";
import ObjectID from "bson-objectid";

export interface QuestionAndAnswer {
    para_id: string;
    qa_id: string;
    question: string;
    answer: string;
}

export interface BookState {
    currentBook: z.infer<typeof IBookModSchema>,
    currentChapterID: string,
    currentSectionID: string,
    currentQuestionIndex: number,
    clearParagraphInfo: boolean,
    currentParagraphID: string,
    title: string,
    retakeOfIncorrectAndSkippedAnswers: boolean,
    userAnswer: string,
    questionHasBeenRead: boolean,
    extractedQsandAs: QuestionAndAnswer[],
    assessmentCompletedBeforeStudying: boolean,
    chapterStudyID: string  // This is the MongoDB ObjectID returned when the '/study/chapter/start' API call is made,
    chapterTestID: string,  // This is the MongoDB ObjectID returned when the '/test/chapter/start' API call is made
    currentTrafficLight: string,
    deletedParagraphID: string,
    showBulkDataInsertedSavedMsg: boolean,
    bookMadeUnavailableAutomatically: boolean,
    chaptersThatNeedAvailabilitySaved: string[]
}

const currentBookInitialState: z.infer<typeof IBookModSchema> = {
    added_on: undefined,
    academic_year: "24-25",
    org: "",
    grade: 0,
    subject: "",
    serial_no: 5,
    title: "",
    desc: "",
    type: ModTypes.book,
    chapters: [],
    available: false
}

const initialState: BookState = {
    currentBook: currentBookInitialState,
    currentChapterID: '',
    currentSectionID: '',
    currentQuestionIndex: 0,
    title: "",
    clearParagraphInfo: false,
    currentParagraphID: '',
    retakeOfIncorrectAndSkippedAnswers: false,
    userAnswer: "",
    questionHasBeenRead: false,
    extractedQsandAs: [],
    assessmentCompletedBeforeStudying: false,
    chapterStudyID: "",
    chapterTestID: "",
    currentTrafficLight: DarkTrafficLight,
    deletedParagraphID: "",
    showBulkDataInsertedSavedMsg: false,
    bookMadeUnavailableAutomatically: false,
    chaptersThatNeedAvailabilitySaved: []
}

export const bookSlice = createSlice({
    name: ModTypes.book,
    initialState,
    reducers: {
        addChapter(state, action: PayloadAction<string>) {
            const updatedChapters = [...state.currentBook.chapters];
            const newChapter = {
                _id: newObjectId(),
                title: action.payload,
                sections: [],
                available: false
            }

            updatedChapters.push(newChapter)
            state.currentBook.chapters = updatedChapters
            state.currentChapterID = newChapter._id
        },
        addSection(state, action: PayloadAction<string>) {
            const updatedSections = [...state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections];

            const newSection = {
                _id: newObjectId(),
                title: action.payload,
                paragraphs: []
            }

            updatedSections.push(newSection)
            state.currentSectionID = newSection._id
            state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections = updatedSections
        },
        setCurrentChapterID(state, action: PayloadAction<string>) {
            state.currentChapterID = action.payload
        },
        setCurrentSectionID(state, action: PayloadAction<string>) {
            state.currentSectionID = action.payload
        },
        updateChapter(state, action: PayloadAction<string>) {
            state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.title = action.payload
        },
        updateSection(state, action: PayloadAction<string>) {
            state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.title = action.payload
        },
        resetChapterID(state) {
            state.currentChapterID = ''
        },
        resetSectionID(state) {
            state.currentSectionID = ''
        },
        resetParagraphID(state) {
            state.currentParagraphID = ''
        },
        deleteChapter(state) {
            state.currentBook.chapters = state.currentBook.chapters.filter(chapter => chapter._id !== state.currentChapterID);
            state.currentChapterID = ''
        },
        addParagraph(state, action: PayloadAction<z.infer<typeof IBookModChapterSectionParagraphSchema>>) {
            const updatedParagraphs = [...state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs!];

            updatedParagraphs.push({
                _id: action.payload._id,
                text: action.payload.text,
                multiple_choices: [],
                questions_and_answers: []
            })

            state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs = updatedParagraphs
        },
        updateParagraphText(state, action: PayloadAction<Pick<z.infer<typeof IBookModChapterSectionParagraphSchema>, '_id' | 'text'>>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === action.payload._id)!

            paraToUpdate.text = action.payload.text
        },
        setCurrentParagraphID(state, action: PayloadAction<string>) {
            state.currentParagraphID = action.payload
        },
        deleteParagraph(state) {
            // const paraToDelete = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)!
            //
            // // Remove the Paragraph from the Section based on the currentChapterID, currentSectionID and the currentParagraphID
            // state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.filter(para => para._id !== paraToDelete._id)
            // state.deletedParagraphID = state.currentParagraphID
            // state.currentParagraphID = ''
        },
        resetDeletedParagraphID(state) {
            state.deletedParagraphID = ''
        },
        saveMultipleChoiceArray(state, action: PayloadAction<z.infer<typeof IBookModChapterSectionParagraphMultipleChoiceSchema>[]>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)!

            paraToUpdate.multiple_choices = [...paraToUpdate.multiple_choices, ...action.payload]
        },
        saveQandAArray(state, action: PayloadAction<z.infer<typeof IBookModChapterSectionParagraphQuestionsAndAnswerSchema>[]>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)!

            paraToUpdate.questions_and_answers = [...paraToUpdate.questions_and_answers, ...action.payload]
        },
        editMultipleChoiceItem(state, action: PayloadAction<z.infer<typeof IBookModChapterSectionParagraphMultipleChoiceSchema>>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)

            const updatedMultipleChoices = [...paraToUpdate!.multiple_choices]
            const updatedMultipleChoiceItem = updatedMultipleChoices.find(item => item._id === action.payload._id)

            Object.assign(updatedMultipleChoiceItem!, action.payload);
            paraToUpdate!.multiple_choices = updatedMultipleChoices
        },
        editQandAItem(state, action: PayloadAction<z.infer<typeof IBookModChapterSectionParagraphQuestionsAndAnswerSchema>>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)

            const updatedQuestionsAndAnswers = [...paraToUpdate!.questions_and_answers]
            const updatedQandAItem = updatedQuestionsAndAnswers.find(item => item._id === action.payload._id)

            Object.assign(updatedQandAItem!, action.payload);
            paraToUpdate!.questions_and_answers = updatedQuestionsAndAnswers
        },
        deleteMultipleChoiceItem(state, action: PayloadAction<string>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)

            const updatedMultipleChoices = [...paraToUpdate!.multiple_choices]

            paraToUpdate!.multiple_choices = updatedMultipleChoices.filter(item => item._id !== action.payload)
        },
        deleteQandAItem(state, action: PayloadAction<string>) {
            const paraToUpdate = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.sections.find(section => section._id === state.currentSectionID)!.paragraphs.find(para => para._id === state.currentParagraphID)

            const updatedQandAs = [...paraToUpdate!.questions_and_answers]

            paraToUpdate!.questions_and_answers = updatedQandAs.filter(item => item._id !== action.payload)
        },
        setShowBulkDataInsertedMsg(state, action: PayloadAction<boolean>) {
            state.showBulkDataInsertedSavedMsg = action.payload
        },
        saveOrgModHeaderInfoInStore(state, action: PayloadAction<z.infer<typeof IOrgModInfoSchema>>) {
            Object.assign(state.currentBook, action.payload)
        },
        setBookID(state, action: PayloadAction<string>) {
            state.currentBook._id = ObjectID(action.payload)
        },
        setCurrentBook(state, action: PayloadAction<z.infer<typeof IBookModSchema>>) {
            state.currentBook._id = ObjectID(action.payload._id!.toString())
            state.currentBook = action.payload
        },
        cloneSections(state, action: PayloadAction<z.infer<typeof IBookModChapterSchema>>) {
            const updatedChapter = state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)

            if (updatedChapter) {
                updatedChapter.sections = action.payload.sections;
            }
        },
        resetBookVars(state) {
            state.currentBook = currentBookInitialState
            state.currentChapterID = ''
            state.currentParagraphID = ''
            state.bookMadeUnavailableAutomatically = false
        },
        setChapterAvailability(state, action: PayloadAction<boolean>) {
            state.currentBook.chapters.find(chapter => chapter._id === state.currentChapterID)!.available = action.payload

            if (!state.chaptersThatNeedAvailabilitySaved.includes(state.currentChapterID)) {
                state.chaptersThatNeedAvailabilitySaved.push(state.currentChapterID);
            }
        },
        removeChapterFromChaptersThatNeedAvailabilitySaved(state) {
            state.chaptersThatNeedAvailabilitySaved = state.chaptersThatNeedAvailabilitySaved.filter(chapterID => chapterID !== state.currentChapterID)
        },
        // This is needed because if all Chapters in a Book are unavailable, the Book should be made unavailable also.
        setBookMadeUnavailableAutomatically(state, action: PayloadAction<boolean>) {
            state.bookMadeUnavailableAutomatically = true

            if (action.payload) {
                // Book needs to be made unavailable
                state.currentBook.available = false
            }
        }
    }
})

export const {
    addChapter,
    addSection,
    setCurrentChapterID,
    setCurrentSectionID,
    updateChapter,
    updateSection,
    resetChapterID,
    resetSectionID,
    resetParagraphID,
    addParagraph,
    updateParagraphText,
    setCurrentParagraphID,
    deleteChapter,
    deleteParagraph,
    saveMultipleChoiceArray,
    saveQandAArray,
    editMultipleChoiceItem,
    deleteMultipleChoiceItem,
    deleteQandAItem,
    editQandAItem,
    setShowBulkDataInsertedMsg,
    saveOrgModHeaderInfoInStore,
    setBookID,
    setCurrentBook,
    cloneSections,
    resetBookVars,
    setChapterAvailability,
    setBookMadeUnavailableAutomatically,
    removeChapterFromChaptersThatNeedAvailabilitySaved
} = bookSlice.actions

export default bookSlice.reducer;