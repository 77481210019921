import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const enum CurrentAudioComponent {
    NONE = 'NONE',
    CHOOSE_CORRECT_SPELLING = 'CHOOSE_CORRECT_SPELLING',
    ANSWER_QUESTION = 'ANSWER_QUESTION',
    TYPE_CORRECT_ANSWER_IN_CHAPTER = 'TYPE_CORRECT_ANSWER_IN_CHAPTER',
    CHOOSE_CORRECT_ANSWER_IN_CHAPTER = 'CHOOSE_CORRECT_ANSWER_IN_CHAPTER',
    SHOW_WORD = 'SHOW_WORD'
}

export interface SharedState {
    correctAudioOnEndFired: boolean,
    wrongAudioOnEndFired: boolean,
    currentAudioComponent: CurrentAudioComponent
}

const initialState: SharedState = {
    correctAudioOnEndFired: false,
    wrongAudioOnEndFired: false,
    currentAudioComponent: CurrentAudioComponent.NONE
}

const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        fireCorrectAudioOnEnd(state) {
            state.correctAudioOnEndFired = true
        },
        resetCorrectAudioOnEnd(state) {
            state.correctAudioOnEndFired = false
        },
        fireWrongAudioOnEnd(state) {
            state.wrongAudioOnEndFired = true
        },
        resetWrongAudioOnEnd(state) {
            state.wrongAudioOnEndFired = false
        },
        setCurrentAudioComponent(state, action: PayloadAction<CurrentAudioComponent>) {
            state.currentAudioComponent = action.payload
        },
    }
})

export const {
    fireCorrectAudioOnEnd,
    resetCorrectAudioOnEnd,
    fireWrongAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} = sharedSlice.actions;

export default sharedSlice.reducer