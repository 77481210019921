import React from 'react';
import {Box, List, ListItem, Modal, ModalClose, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";

export type ModalPopupData = {
    primary_text: string,
    secondary_text?: string
}

interface Props {
    open: boolean,
    closeDialog: () => void,
    title: string,
    items: ModalPopupData[]
}

export const ModalPopup: React.FC<Props> = ({open, closeDialog, title, items}) => {
    return (
        <Modal
            aria-labelledby="modal-title"
            open={open}
            onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if (reason === 'closeClick') {
                    closeDialog();
                }
            }}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 500}}
        >
            <Sheet
                variant="outlined"
                sx={{
                    minWidth: 400,
                    maxWidth: 600,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                    overflow: 'auto',
                    maxHeight: 500
                }}
            >
                <ModalClose variant="plain" sx={{m: 1}}/>
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                    maxWidth="500px"
                >
                    {title}
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        gap: 2,
                        flexWrap: 'wrap'
                    }}
                >
                    {items.map((item, index) => (
                        <div key={index}>
                            <List
                                size='sm'
                                variant="soft"
                                sx={{
                                    borderRadius: 'sm',
                                    fontSize: 'lg',
                                }}
                            >
                                <ListItem>
                                    {item.secondary_text ? `${item.primary_text} / ${item.secondary_text}` : item.primary_text}
                                </ListItem>
                            </List>
                        </div>
                    ))}
                </Box>
            </Sheet>
        </Modal>
    );
}