import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ModTypes} from "src/utils/constants";
import shuffle from "lodash/shuffle";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import {IBookModChapterSection} from "@backend/mongoose.gen";

export interface QuestionAndAnswer {
    section_id: string,
    para_id: string;
    qa_id: string;
    question: string;
    answer: string;
}

export interface ChapterState {
    chapterID: string,
    currentQuestionIndex: number,
    clearParagraphInfo: boolean,
    currentSectionID: string,
    currentParagraphID: string,
    sections: IBookModChapterSection[],
    title: string,
    retakeOfIncorrectAndSkippedAnswers: boolean,
    userAnswer: string,
    questionHasBeenRead: boolean,
    extractedQsandAs: QuestionAndAnswer[],
    assessmentCompletedBeforeStudying: boolean,
    chapterStudyID: string  // This is the MongoDB ObjectID returned when the '/study/chapter/start' API call is made,
    chapterTestID: string,  // This is the MongoDB ObjectID returned when the '/test/chapter/start' API call is made
    newParagraphText: string,
    currentTrafficLight: string,
    initialSections: IBookModChapterSection[]
}

const initialState: ChapterState = {
    chapterID: "",
    currentQuestionIndex: 0,
    title: "",
    clearParagraphInfo: false,
    currentParagraphID: '',
    currentSectionID: '',
    sections: [],
    retakeOfIncorrectAndSkippedAnswers: false,
    userAnswer: "",
    questionHasBeenRead: false,
    extractedQsandAs: [],
    assessmentCompletedBeforeStudying: false,
    chapterStudyID: "",
    chapterTestID: "",
    newParagraphText: "",
    currentTrafficLight: DarkTrafficLight,
    initialSections: []
}

export const chapterSlice = createSlice({
    name: ModTypes.chapter,
    initialState,
    reducers: {
        setCurrentSectionID(state, action: PayloadAction<string>) {
            state.currentSectionID = action.payload
        },
        saveSelectedChapterInfo(state, action: PayloadAction<{
            chapterID: string,
            title: string,
            sections: IBookModChapterSection[],
            assessmentCompletedBeforeStudying: boolean,
            saveSectionsAsInitialSelections?: boolean
        }>) {
            state.chapterID = action.payload.chapterID === '' ? state.chapterID : action.payload.chapterID;
            state.retakeOfIncorrectAndSkippedAnswers = false;
            state.title = action.payload.title === '' ? state.title : action.payload.title;
            state.sections = action.payload.sections;
            state.extractedQsandAs = shuffle(
                action.payload.sections
                    .find(section => section._id === state.currentSectionID)
                    ?.paragraphs
                    .flatMap((paragraph) => {
                        return paragraph.questions_and_answers.map((qa: any) => {
                            return {
                                section_id: state.currentSectionID,
                                para_id: paragraph._id,
                                qa_id: qa._id.toString(),
                                question: qa.question,
                                answer: qa.answer
                            };
                        });
                    }) || []
            );
            state.assessmentCompletedBeforeStudying = action.payload.assessmentCompletedBeforeStudying

            if (action.payload.saveSectionsAsInitialSelections) {
                state.initialSections = [...action.payload.sections]
            }
        },
        setExtractedQsandAs(state) {
            state.extractedQsandAs = shuffle(state.sections
                .find(section => section._id === state.currentSectionID)
                ?.paragraphs
                .flatMap((paragraph) => {
                    return paragraph.questions_and_answers.map((qa: any) => {
                        return {
                            section_id: state.currentSectionID,
                            para_id: paragraph._id,
                            qa_id: qa._id.toString(),
                            question: qa.question,
                            answer: qa.answer
                        };
                    });
                }) || []
            )
        },
        incrementCurrentQuestionIndex(state) {
            state.currentQuestionIndex++;
        },
        resetCurrentQuestionIndex(state) {
            state.currentQuestionIndex = 0;
        },
        updateAnswer(state, action: PayloadAction<string>) {
            state.userAnswer = action.payload
        },
        setQuestionHasBeenRead(state, action: PayloadAction<boolean>) {
            state.questionHasBeenRead = action.payload;
        },
        saveChapterStudyID(state, action: PayloadAction<string>) {
            state.chapterStudyID = action.payload
        },
        saveChapterTestID(state, action: PayloadAction<string>) {
            state.chapterTestID = action.payload
        },
        setCurrentTrafficLight(state, action: PayloadAction<string>) {
            state.currentTrafficLight = action.payload
        }
    }
})

export const {
    saveSelectedChapterInfo,
    incrementCurrentQuestionIndex,
    resetCurrentQuestionIndex,
    updateAnswer,
    setQuestionHasBeenRead,
    saveChapterStudyID,
    saveChapterTestID,
    setCurrentTrafficLight,
    setCurrentSectionID,
    setExtractedQsandAs
} = chapterSlice.actions

export default chapterSlice.reducer;