import {z} from 'zod';

// Helper type to convert a TypeScript type to a Zod schema
type ZodType<T> =
    T extends number ? z.ZodNumber :
        T extends string ? z.ZodString :
            T extends boolean ? z.ZodBoolean :
                T extends Date ? z.ZodDate :
                    T extends (infer U)[] ? z.ZodArray<ZodType<U>> :
                        T extends object ? ZodObject<T> :
                            never;

// Helper type for object schemas
type ZodObject<T> = z.ZodObject<{ [K in keyof T]: ZodType<T[K]> }>;

// Helper function to determine the Zod schema for a given value
const getZodSchema = (value: unknown): z.ZodTypeAny => {
    if (typeof value === 'string') return z.string();
    if (typeof value === 'number') return z.number();
    if (typeof value === 'boolean') return z.boolean();
    if (value === null) return z.null();
    if (Array.isArray(value)) return z.array(getZodSchema(value[0]));
    if (typeof value === 'object') return createZodSchema<typeof value>();
    return z.unknown();
};

// Helper function to get the appropriate Zod schema for a given type
export const createZodSchema = <T extends object>(): z.ZodObject<{
    [K in keyof T]: z.ZodTypeAny;
}> => {
    const shape: { [K in keyof T]: z.ZodTypeAny } = {} as any;

    for (const key in Object.assign({}) as T) {
        const value = ({} as T)[key];
        let fieldSchema = getZodSchema(value);

        // Make the property optional if it's undefined in the type
        if (value === undefined) {
            fieldSchema = fieldSchema.optional();
        }

        shape[key] = fieldSchema;
    }

    return z.object(shape);
};

