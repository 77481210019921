import React, {Dispatch, FC, SetStateAction, useRef, useState} from 'react';
import {
    Box,
    Button,
    ColorPaletteProp,
    IconButton,
    Input,
    List,
    ListItem,
    Sheet,
    Snackbar,
    Table, Textarea
} from "@mui/joy";
import {getAnswerTextToEnter, newObjectId, setInputColor} from "src/utils/functions";
import {z} from "zod";
import {TextInputColor} from "src/utils/constants";
import {uniq} from "lodash";
import Typography from "@mui/joy/Typography";
import {KeyboardArrowDown} from "@mui/icons-material";
import {useAppSelector, useAppDispatch} from "src/app/hooks";
import {RootState} from "src/app/store";
import {deleteConfirmationDialog} from "src/components/Shared/DeleteModal";
import {
    deleteMultipleChoiceItem,
    editMultipleChoiceItem,
    saveMultipleChoiceArray
} from "src/slices/bookSlice";
import {
    IBookModChapterSectionParagraphMultipleChoiceSchema,
    IBookModChapterSectionParagraphSchema,
    IBookModChapterSchema
} from "src/zodSchemas";

export const MultipleChoice: FC = () => {
    const [open, setOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")
    const [rowToDelete, setRowToDelete] = useState('')

    const currentChapterID = useAppSelector((state: RootState) => state.book.currentChapterID);
    const currentSectionID = useAppSelector((state: RootState) => state.book.currentSectionID);
    const currentParagraphID = useAppSelector((state: RootState) => state.book.currentParagraphID);
    const chapters = useAppSelector((state: RootState) => state.book.currentBook.chapters);

    const multipleChoiceArray: z.infer<typeof IBookModChapterSectionParagraphMultipleChoiceSchema>[] | undefined = useAppSelector((state: RootState) => state.book.currentBook.chapters?.find(chapter => chapter._id === currentChapterID)?.sections?.find(section => section._id === currentSectionID)?.paragraphs?.find(para => para._id === currentParagraphID)?.multiple_choices)

    const [multipleChoiceInfo, setMultipleChoiceInfo] = useState<z.infer<typeof IBookModChapterSectionParagraphMultipleChoiceSchema>>(
        {
            _id: newObjectId(),
            question: '',
            answer: '',
            options: ['', '', '']
        }
    )
    const [questionInputColor, setQuestionInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [answerInputColor, setAnswerInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option1InputColor, setOption1InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option2InputColor, setOption2InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [option3InputColor, setOption3InputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [editClicked, setEditClicked] = useState(false)

    const refQuestion = useRef<HTMLTextAreaElement | null>(null);
    const refAnswer = useRef<HTMLInputElement | null>(null);
    const refOption1 = useRef<HTMLInputElement | null>(null);
    const refOption2 = useRef<HTMLInputElement | null>(null);
    const refOption3 = useRef<HTMLInputElement | null>(null);

    const appDispatch = useAppDispatch();

    const checkForQuestionAnswerOptionsMatch = (questionOrAnswer: string) => {
        const trimmedQuestionOrAnswer = questionOrAnswer.trim().toLowerCase();
        const nonEmptyOptions = multipleChoiceInfo.options.filter(item => item.trim() !== '').map(item => item.toLowerCase());
        const matchingIndex = nonEmptyOptions.findIndex(option => option === trimmedQuestionOrAnswer);

        if (matchingIndex !== -1) {
            setSnackbarMsg(`Option has already been entered as the ${questionOrAnswer === multipleChoiceInfo.answer ? 'Answer' : 'Question'}.`);
            const updatedOptions = [...multipleChoiceInfo.options];
            updatedOptions[matchingIndex] = ''; // make matching option empty
            setMultipleChoiceInfo(prevState => ({
                ...prevState,
                options: updatedOptions
            }));

            if (matchingIndex === 0) {
                refOption1.current!.focus();
            } else if (matchingIndex === 1) {
                refOption2.current!.focus();
            } else {
                refOption3.current!.focus();
            }

            setSnackbarMsgColor('danger');
            setShowSnackbarMsg(true);
            return true; // indicate match found
        }
        return false; // indicate no match found
    }

    const cleanUpAfterSaving = (operation: string) => {
        setMultipleChoiceInfo({
            _id: newObjectId(),
            question: '',
            answer: '',
            options: ['', '', '']
        })
        setOption1InputColor(TextInputColor.NEUTRAL)
        setOption2InputColor(TextInputColor.NEUTRAL)
        setOption3InputColor(TextInputColor.NEUTRAL)

        setSnackbarMsg(`Multiple Choice Info has been ${operation}.`)
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
    };

    const doValidation = () => {
        const chapterIDResult = IBookModChapterSchema.pick({_id: true}).safeParse({_id: currentChapterID})

        if (!chapterIDResult.success) {
            setSnackbarMsg('Please add and select a Chapter before adding a Paragraph.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        const paraIDResult = IBookModChapterSectionParagraphSchema.pick({_id: true}).safeParse({_id: currentParagraphID})

        if (!paraIDResult.success) {
            setSnackbarMsg('Please add and select a Paragraph before adding a Multiple Choice Question.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        const mcResult = IBookModChapterSectionParagraphMultipleChoiceSchema.safeParse(multipleChoiceInfo)

        if (!mcResult.success) {
            highlightInvalidField(mcResult.error.flatten().fieldErrors.options, [refOption1.current!, refOption2.current!, refOption3.current!], [setOption1InputColor, setOption2InputColor, setOption3InputColor])
            highlightInvalidField(mcResult.error.flatten().fieldErrors.answer, [refAnswer.current!], [setAnswerInputColor])
            highlightInvalidField(mcResult.error.flatten().fieldErrors.question, [refQuestion.current!], [setQuestionInputColor])
            return
        }

        const duplicateOptionsFound = uniq(multipleChoiceInfo.options.filter((item) => item.trim() !== '').map((item) => item.toLowerCase())).length < multipleChoiceInfo.options.length;

        if (duplicateOptionsFound) {
            setSnackbarMsg('Duplicate options are not allowed.')
            const option1 = multipleChoiceInfo.options[0].trim()
            const option2 = multipleChoiceInfo.options[1].trim()
            const option3 = multipleChoiceInfo.options[2].trim()

            if (option1.toLowerCase() === option2.toLowerCase() && option2.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, '', '']
                }))
                setOption2InputColor(TextInputColor.DANGER)
                refOption2.current!.focus()
                setOption3InputColor(TextInputColor.DANGER)
            } else if (option1.toLowerCase() === option2.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, '', option3]
                }))
                setOption2InputColor(TextInputColor.DANGER)
                refOption2.current!.focus()
            } else if (option1.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, option2, '']
                }))
                setOption3InputColor(TextInputColor.DANGER)
                refOption3.current!.focus()
            } else if (option2.toLowerCase() === option3.toLowerCase()) {
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    options: [option1, option2, '']
                }))
                setOption3InputColor(TextInputColor.DANGER)
                refOption3.current!.focus()
            }

            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (!editClicked) {
            const mcArray = chapters.find((chapter: {
                _id: string;
            }) => chapter._id === currentChapterID)!.sections.find((section: {
                _id: string;
            }) => section._id === currentSectionID)!.paragraphs.find((para: {
                _id: string;
            }) => para._id === currentParagraphID)!.multiple_choices
            const questionHasBeenAddedPreviously = (mcArray.filter((m: {
                question: string;
            }) => m.question.toLowerCase() === multipleChoiceInfo.question.trim().toLowerCase()).length) > 0

            if (questionHasBeenAddedPreviously) {
                setSnackbarMsg(`'${multipleChoiceInfo.question}' has already been added.`)
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    question: ''
                }))
                refQuestion.current!.focus()
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }

            const answerHasBeenAddedPreviously = (mcArray.filter((m: {
                answer: string;
            }) => m.answer.toLowerCase() === multipleChoiceInfo.answer.trim()).length) > 0

            if (answerHasBeenAddedPreviously) {
                setSnackbarMsg(`'${multipleChoiceInfo.answer}' has already been added.`)
                setMultipleChoiceInfo(prevState => ({
                    ...prevState,
                    answer: ''
                }))
                refAnswer.current!.focus()
                setSnackbarMsgColor('danger')
                setShowSnackbarMsg(true)
                return
            }
        }

        const questionAndAnswerAreTheSame = (multipleChoiceInfo.question.trim().toLowerCase() === multipleChoiceInfo.answer.trim().toLowerCase())

        if (questionAndAnswerAreTheSame) {
            setSnackbarMsg('Question and Answer must be different.')
            setMultipleChoiceInfo(prevState => ({
                ...prevState,
                answer: ''
            }))
            refAnswer.current!.focus()
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            return
        }

        if (checkForQuestionAnswerOptionsMatch(multipleChoiceInfo.question)) {
            return
        }

        if (checkForQuestionAnswerOptionsMatch(multipleChoiceInfo.answer)) {
            return
        }

        // Info is valid
        refQuestion.current!.focus()

        if (editClicked) {
            appDispatch(editMultipleChoiceItem(multipleChoiceInfo))
            setEditClicked(false)
            cleanUpAfterSaving('edited');
        } else {
            appDispatch(saveMultipleChoiceArray([multipleChoiceInfo]))
            cleanUpAfterSaving('added');
        }
    };

    const highlightInvalidField = (fieldName: string[] | undefined, inputElem: HTMLInputElement[] | HTMLTextAreaElement[], setInputColorAction: Dispatch<SetStateAction<TextInputColor>>[]) => {
        if (fieldName !== undefined) {
            setSnackbarMsg(fieldName[0])

            if (inputElem.length === 1) {
                inputElem[0].focus()
                setSnackbarMsg(fieldName[0])
                setInputColorAction[0](TextInputColor.DANGER)
            } else {
                // Options Array
                const option1 = inputElem[0].value.trim()
                const option2 = inputElem[1].value.trim()
                const option3 = inputElem[2].value.trim()

                if (fieldName.length === 3) {
                    // All Options are invalid.
                    setInputColorAction[0](TextInputColor.DANGER)
                    setInputColorAction[1](TextInputColor.DANGER)
                    setInputColorAction[2](TextInputColor.DANGER)
                    inputElem[0].focus()
                } else if (fieldName.length === 2) {
                    // 2 Options are invalid.
                    if (option1 !== '') {
                        // Error must be on the second and third options
                        setInputColorAction[1](TextInputColor.DANGER)
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[1].focus()
                    } else if (option2 !== '') {
                        // Error must be on the first and third options
                        setInputColorAction[0](TextInputColor.DANGER)
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[0].focus()
                    } else if (option3 !== '') {
                        // Error must be on the first and second options
                        setInputColorAction[0](TextInputColor.DANGER)
                        setInputColorAction[1](TextInputColor.DANGER)
                        inputElem[0].focus()
                    }
                } else if (fieldName.length === 1) {
                    // 1 Option is invalid.
                    if (option1 === '') {
                        setInputColorAction[0](TextInputColor.DANGER)
                        inputElem[0].focus()
                    } else if (option2 === '') {
                        setInputColorAction[1](TextInputColor.DANGER)
                        inputElem[1].focus()
                    } else if (option3 === '') {
                        setInputColorAction[2](TextInputColor.DANGER)
                        inputElem[2].focus()
                    } else {
                        // All options are present, but an option has been entered twice.
                        // Highlight the duplicated entries.
                        if (option1 === option2 && option1 === option3) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[1](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option1 === option2) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[1](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option1 === option3) {
                            setInputColorAction[0](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[0].focus()
                        } else if (option2 === option3) {
                            setInputColorAction[1](TextInputColor.DANGER)
                            setInputColorAction[2](TextInputColor.DANGER)
                            inputElem[1].focus()
                        }
                    }
                }
            }

            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
        }
    }

    const deleteChoice = () => {
        appDispatch(deleteMultipleChoiceItem(rowToDelete))
        setShowDeleteConfirmation(false)
        setRowToDelete('')
        setEditClicked(false)
        refQuestion.current?.focus()
    };

    const editChoice = (_idToEdit: string) => {
        setEditClicked(true)
        setMultipleChoiceInfo(multipleChoiceArray!.find(mc => mc._id === _idToEdit)!)
        refQuestion.current?.focus()
    };

    return (
        <List size="sm"
              sx={() => ({
                  'marginLeft': '20px',
                  'marginTop': '0px',
                  'marginBottom': '0px',
                  '--ListItem-paddingRight': '16px',
                  '--ListItem-paddingLeft': '21px',
                  '--ListItem-startActionTranslateX': '-50%',
              })}
        >
            <ListItem
                nested
                sx={{my: 0}}
                startAction={
                    <IconButton
                        variant="plain"
                        size="sm"
                        color="neutral"
                        onClick={() => setOpen(!open)}
                    >
                        <KeyboardArrowDown
                            sx={{transform: open ? 'initial' : 'rotate(-90deg)'}}
                        />
                    </IconButton>
                }
            >
                <ListItem>
                    <Typography
                        level="inherit"
                        sx={{
                            fontWeight: open ? 'bold' : undefined,
                            color: open ? 'text.primary' : 'inherit',
                        }}
                    >
                        Paragraph: Multiple Choice
                    </Typography>
                    <div style={{marginTop: '1px'}}>
                        {` ${multipleChoiceArray ? multipleChoiceArray.length : 0}`}
                    </div>
                </ListItem>
                {open && (
                    <Box
                        sx={{
                            flex: 1,
                            p: 3,
                            boxShadow: 'lg',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Textarea color={questionInputColor as ColorPaletteProp}
                                      placeholder='Question *'
                                      style={{width: "100%"}}
                                      value={multipleChoiceInfo.question}
                                      slotProps={{
                                          textarea: {ref: refQuestion}
                                      }}
                                      onChange={(event) => {
                                          let textToEnter = event.target.value.toLowerCase().startsWith("question:") ? event.target.value.slice(9).trim() : event.target.value

                                          textToEnter = event.target.value.toLowerCase().startsWith("q:") ? event.target.value.slice(2).trim() : event.target.value

                                          textToEnter = textToEnter.replace(/^\n+/, '');  // Remove hard returns at the start of the text

                                          // Replace any occurrences of one or more newline characters (\n) followed by zero or more whitespace characters (\s*) followed by another newline character (\n) with a single newline character (\n). This effectively removes the hard return from the original string.
                                          textToEnter = textToEnter.replace(/\n\s*\n/g, '\n');

                                          // If the text entered has 4 or more hard returns, split up the text into an array and populate the Question, Answer and 3 Options.
                                          if (textToEnter.split('\n').length >= 4) {
                                              const [question, answer, option1, option2, option3] = textToEnter.split('\n')

                                              setInputColor(question, setQuestionInputColor)
                                              setMultipleChoiceInfo(prevState => ({
                                                      ...prevState,
                                                      question: question,
                                                      answer: answer,
                                                      options: [option1, option2, option3]
                                                  })
                                              )
                                          } else {
                                              setInputColor(textToEnter, setQuestionInputColor)
                                              setMultipleChoiceInfo(prevState => ({
                                                      ...prevState,
                                                      question: textToEnter
                                                  })
                                              )
                                          }
                                      }}
                            />
                            <Input color={answerInputColor as ColorPaletteProp}
                                   fullWidth={true}
                                   placeholder='Answer *'
                                   value={multipleChoiceInfo.answer}
                                   slotProps={{
                                       input: {
                                           ref: refAnswer
                                       },
                                   }}
                                   onChange={(event) => {
                                       setInputColor(event.target.value, setAnswerInputColor)
                                       setMultipleChoiceInfo(prevState => ({
                                               ...prevState,
                                               answer: getAnswerTextToEnter(event.target.value)
                                           })
                                       )
                                   }}
                            />
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                boxShadow: 'lg',
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Input color={option1InputColor as ColorPaletteProp}
                                   fullWidth={true}
                                   placeholder='Option 1 *'
                                   value={multipleChoiceInfo.options[0]}
                                   slotProps={{
                                       input: {
                                           ref: refOption1
                                       },
                                   }}
                                   onChange={(event) => {
                                       setInputColor(event.target.value, setOption1InputColor)

                                       // Split the options and populate all 3 when a comma is in the text. Example "Rivers, valleys, and insects"
                                       let optionsArray = event.target.value.split(",")

                                       if (optionsArray.length === 3) {
                                           let option3 = optionsArray[2].toLowerCase().trim().startsWith("and") ? optionsArray[2].trim().slice(3).trim() : optionsArray[2].trim()

                                           option3 = option3.endsWith('.') ? option3.slice(0, -1) : option3

                                           setMultipleChoiceInfo(prevState => ({
                                                   ...prevState,
                                                   options: [optionsArray[0].trim(), optionsArray[1].trim(), option3]
                                               })
                                           )
                                       } else {
                                           setMultipleChoiceInfo(prevState => ({
                                                   ...prevState,
                                                   options: [event.target.value, prevState.options[1], prevState.options[2]]
                                               })
                                           )
                                       }
                                   }}
                            />
                            <Input color={option2InputColor as ColorPaletteProp}
                                   fullWidth={true}
                                   placeholder='Option 2 *'
                                   value={multipleChoiceInfo.options[1]}
                                   slotProps={{
                                       input: {
                                           ref: refOption2
                                       },
                                   }}
                                   onChange={(event) => {
                                       setInputColor(event.target.value, setOption2InputColor)
                                       setMultipleChoiceInfo(prevState => ({
                                               ...prevState,
                                               options: [prevState.options[0], event.target.value, prevState.options[2]]
                                           })
                                       )
                                   }}
                            />
                            <Input color={option3InputColor as ColorPaletteProp}
                                   fullWidth={true}
                                   placeholder='Option 3 *'
                                   value={multipleChoiceInfo.options[2]}
                                   slotProps={{
                                       input: {
                                           ref: refOption3
                                       },
                                   }}
                                   onChange={(event) => {
                                       setInputColor(event.target.value, setOption3InputColor)
                                       setMultipleChoiceInfo(prevState => ({
                                               ...prevState,
                                               options: [prevState.options[0], prevState.options[1], event.target.value]
                                           })
                                       )
                                   }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 3,
                                marginTop: '5px'
                            }}>
                            <Button
                                onClick={
                                    () => doValidation()
                                }
                            >
                                <Typography
                                    sx={{
                                        color: 'white'
                                    }}
                                >Save Multiple Choice Info</Typography>
                            </Button>
                            <Button
                                sx={{
                                    color: 'white'
                                }}
                                onClick={
                                    () => {
                                        setEditClicked(false)
                                        setMultipleChoiceInfo({
                                            _id: newObjectId(),
                                            question: '',
                                            answer: '',
                                            options: ['', '', '']
                                        })
                                        refQuestion.current?.focus()
                                    }
                                }
                            >
                                <Typography
                                    sx={{
                                        color: 'white'
                                    }}
                                >Clear Multiple Choice Info</Typography>
                            </Button>
                        </Box>
                        <div>
                            <Sheet sx={{height: 400, overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                                <Table
                                    aria-label="table with sticky header"
                                    stickyHeader
                                    stickyFooter
                                >
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Answer</th>
                                            <th>Option 1</th>
                                            <th>Option 2</th>
                                            <th>Option 3</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {multipleChoiceArray?.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.question}</td>
                                                <td>{row.answer}</td>
                                                <td>{row.options[0]}</td>
                                                <td>{row.options[1]}</td>
                                                <td>{row.options[2]}</td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        editChoice(row._id)
                                                                    }
                                                                }>
                                                            Edit
                                                        </Button>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{display: 'flex', gap: 1}}>
                                                        <Button size="sm" variant="soft" color="danger"
                                                                onClick={
                                                                    () => {
                                                                        setRowToDelete(row._id)
                                                                        setShowDeleteConfirmation(true)
                                                                    }
                                                                }>
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan={7} style={{textAlign: 'center'}}>
                                            Count: {multipleChoiceArray?.length}
                                        </td>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Sheet>
                        </div>
                        {
                            (() => {
                                if (showSnackbarMsg)
                                    return (
                                        <Snackbar
                                            size="lg"
                                            variant="solid"
                                            color={snackbarMsgColor as ColorPaletteProp}
                                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                            autoHideDuration={3000}
                                            open={true}
                                            onClose={() => {
                                                setShowSnackbarMsg(false);
                                            }}
                                            sx={{
                                                justifyContent: 'center',
                                                padding: '5px',
                                                whiteSpace: 'pre-line'
                                            }}
                                        >
                                            {snackbarMsg}
                                        </Snackbar>
                                    )
                            })()
                        }
                        {
                            (() => {
                                if (showDeleteConfirmation)
                                    return deleteConfirmationDialog('Choice', setShowDeleteConfirmation, deleteChoice)
                            })()
                        }
                    </Box>
                )}
            </ListItem>
        </List>
    )
}

export default MultipleChoice