import {useRef} from "react";
import {VoiceGender} from "src/slices/wordsSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {Howl} from "howler";
import correctSound from 'src/audio/correct.mp3';
import wrongSound from 'src/audio/wrong.mp3';
import {fireCorrectAudioOnEnd, fireWrongAudioOnEnd} from "src/slices/sharedSlice";
import {Paths} from "src/utils/constants";
import {useNavigate} from "react-router-dom";

export const useSharedMgr = () => {
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();

    const voiceGender = useAppSelector((state: RootState) => state.words.voiceGender);

    const synth = window.speechSynthesis;

    const refVoice = useRef((() => {
        if (voiceGender === VoiceGender.FEMALE) {
            return synth?.getVoices()[2];
        }

        return synth?.getVoices()[0];
    })());

    const speak = (text: string) => {
        if (synth) {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.voice = refVoice.current;
            synth.speak(utterance);
        }
    }

    const speakWithEndFlag = async (text: string) => new Promise((resolve) => {
        const utterance = new SpeechSynthesisUtterance(text);
        synth.speak(utterance);
        utterance.onend = resolve;
    });

    const stopSpeaking = () => {
        if (synth) {
            synth.cancel();
        }
    }

    const playCorrectAudio = () => {
        const correctAudio = new Howl({
            src: [correctSound],
            onend: () => {
                appDispatch(fireCorrectAudioOnEnd())
            }
        });

        correctAudio.play()
    }

    const playWrongAudio = () => {
        const wrongAudio = new Howl({
            src: [wrongSound],
            onend: () => {
                appDispatch(fireWrongAudioOnEnd())
            }
        });

        wrongAudio.play()
    }

    const setNavigationPathToHomeIfNeeded = (IDWasInURL: boolean) => {
        // I'm not sure why 'navigate' is needed here, but without it, the update doesn't work.
        if (IDWasInURL)
            navigate(Paths.Home)
    };

    return {
        playCorrectAudio,
        playWrongAudio,
        setNavigationPathToHomeIfNeeded,
        speak,
        speakWithEndFlag,
        stopSpeaking
    }
}