import React, {useEffect, useRef} from 'react';
import {Header} from "src/components/Header";
import {Box, Button, Input, Snackbar} from "@mui/joy";
import {Footer} from "src/components/Footer";
import {
    setAutoSubmit,
    setWordHasBeenPlayed,
    updateWordSpelling
} from "src/slices/wordsSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {useSpellingMgr} from "src/hooks/useSpellingMgr";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Typography from "@mui/joy/Typography";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {resizeButtonSX} from "src/utils/ui";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from "src/slices/sharedSlice";

export const ShowWord: React.FC = () => {
    const [showTypeWordMsg, setShowTypeWordMsg] = React.useState<boolean>(false);

    const refInput = useRef<HTMLInputElement>(null);

    const autoSubmit = useAppSelector((state: RootState) => state.words.autoSubmit);
    const currentIndex = useAppSelector((state: RootState) => state.words.currentIndex);
    const words = useAppSelector((state: RootState) => state.words.words);
    const wordSpelling = useAppSelector((state: RootState) => state.words.wordSpelling);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);
    const currentTrafficLight = useAppSelector((state: RootState) => state.words.currentTrafficLight);

    const currentWord = words[currentIndex]?.word ? words[currentIndex].word : ""
    const wordUsage = words[currentIndex]?.usage ? words[currentIndex].usage : ""

    const {
        incrementIndexOrGoToSpellingResult,
        replaceWordWithUnderscoreChars,
        submitSpelling
    } = useSpellingMgr();
    const {playWrongAudio, speak, stopSpeaking} = useSharedMgr();

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);

    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.SHOW_WORD))
    }, []);

    useEffect(() => {
        if (correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.SHOW_WORD) {
            incrementIndexOrGoToSpellingResult()
        }

        appDispatch(resetCorrectAudioOnEnd());
    }, [correctAudioOnEndFired])

    useEffect(() => {
        if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.SHOW_WORD) {
            if (wordSpelling.trim() === '' && !autoSubmit) {
                refInput.current!.disabled = false
                refInput.current?.focus();
            } else {
                incrementIndexOrGoToSpellingResult()
                appDispatch(setAutoSubmit(false));
            }
        }

        appDispatch(resetWrongAudioOnEnd());
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        refInput.current!.disabled = false
        refInput.current?.focus();
        appDispatch(updateWordSpelling(''));
        speak(currentWord);
        appDispatch(setWordHasBeenPlayed(true));
    }, [currentIndex]);

    const doSubmission = () => {
        refInput.current!.disabled = true
        submitSpelling();
        stopSpeaking();
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Header/>
            <Title title={'Fill in the Blank'}/>
            <Body boxGap={7}>
                <Typography fontSize="x-large">
                    {replaceWordWithUnderscoreChars(wordUsage, currentWord)}
                </Typography>
                <Input
                    slotProps={{
                        input: {
                            ref: refInput
                        },
                    }}
                    value={wordSpelling}
                    placeholder="Type Word Here"
                    size="lg"
                    onKeyDown={
                        (event) => {
                            if (event.key === 'Enter' && wordSpelling.trim() !== '' && submitWhenEnterKeyIsPressed === 'yes') {
                                doSubmission()
                            }
                        }
                    }
                    onChange={(event) => appDispatch(updateWordSpelling(event.target.value))}
                    spellCheck="false"
                />
                <Box
                    component="img"
                    sx={{
                        height: 125
                    }}
                    alt={
                        'Traffic Light'
                    }
                    src={
                        currentTrafficLight
                    }
                />
            </Body>
            <ButtonRow>
                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                    <Button
                        sx={resizeButtonSX()}
                        onClick={
                            () => {
                                speak(currentWord);
                                refInput.current?.focus();
                            }
                        }
                    >
                        Play Word
                    </Button>
                    <Button sx={resizeButtonSX()} variant="solid" color="primary" onClick={
                        () => {
                            if (wordSpelling.trim() === '') {
                                refInput.current!.disabled = true
                                refInput.current?.focus();
                                playWrongAudio()
                                setShowTypeWordMsg(true)
                                return
                            }

                            doSubmission()
                        }
                    }>
                        Submit
                    </Button>
                </ButtonGroup>
            </ButtonRow>
            <Footer/>
            <Snackbar
                size="lg"
                variant="solid"
                color="warning"
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={1750}
                open={showTypeWordMsg}
                onClose={() => {
                    setShowTypeWordMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                }}
            >
                Please spell the word and then Submit.
            </Snackbar>
        </div>
    );
}
