import React, {useState} from 'react';
import {Box, Button, CircularProgress, Input, Sheet, Snackbar} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {
    useGenerateSummaryMutation,
    useGenerateQAndAsMutation
} from "src/utils/api-service";
import {IBookModChapterSectionSchema} from "src/zodSchemas";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {newObjectId} from "src/utils/functions";
import {addParagraph, saveMultipleChoiceArray, saveQandAArray, setCurrentParagraphID} from "src/slices/bookSlice";

export const ParseAndImportPDF: React.FC = () => {
    const [paragraphText, setParagraphText] = useState('')
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)

    const currentSectionID = useAppSelector((state: RootState) => state.book.currentSectionID);

    const [generateSummary, {
        isLoading: summaryIsLoading
    }] = useGenerateSummaryMutation();
    const [generateQuestionsAndAnswers, {
        isLoading: QandAsAreLoading
    }] = useGenerateQAndAsMutation();

    const appDispatch = useAppDispatch();

    const processParagraph = async () => {
        if (paragraphText.trim() === '') {
            setSnackbarMsg('Paragraph Text to Parse is Required')
            setShowSnackbarMsg(true)
            return
        }

        const sectionIDResult = IBookModChapterSectionSchema.pick({_id: true}).safeParse({_id: currentSectionID})

        if (!sectionIDResult.success) {
            setSnackbarMsg('Please add and select a Section before adding a Paragraph.')
            setShowSnackbarMsg(true)
            return
        }

        try {
            console.clear()

            const summary = await generateSummary(`Summarize the essential points in the following paragraph by removing the extraneous text and making the paragraph more concise. The output should be in paragraph format. ${paragraphText.trim()}`)

            // @ts-ignore
            const summarizedPara = summary.data.choices[0].message.content

            // Save Summarized Paragraph
            const paraID = newObjectId()

            appDispatch(addParagraph({
                _id: paraID,
                text: summarizedPara,
                questions_and_answers: [],
                multiple_choices: []
            }))

            appDispatch(setCurrentParagraphID(paraID))

            const MCQs = await generateQuestionsAndAnswers("Create at least five multiple choice questions and answers from the following paragraph. '" +
                `${summarizedPara}` +
                "' Example output: question1, correct_answer1, wrong_answer1, wrong_answer2, wrong_answer3, question2, correct_answer2, wrong_answer1, wrong_answer2, wrong_answer3, etc" +
                "Do not use any labels, numbering, lettering or dashes in front of the question and answers in the responses. Put each wrong answer on a new line. Do not put any separators between the MCQ sets.");

            // @ts-ignore
            console.log(MCQs.data.choices[0].message.content)

            // Save MCQs
            // @ts-ignore
            const MCQArray = MCQs.data.choices[0].message.content.split("\n").map((item: string) => item.trim()).filter((item: string) => item !== "");

            for (let i = 0; i < MCQArray.length; i += 5) {
                const question = MCQArray[i];
                const correctAnswer = MCQArray[i + 1];
                const wrongAnswers = [MCQArray[i + 2], MCQArray[i + 3], MCQArray[i + 4]];

                console.log("Question:", question);
                console.log("Correct Answer:", correctAnswer);
                console.log("Wrong Answers:", wrongAnswers);

                appDispatch(saveMultipleChoiceArray([{
                    _id: newObjectId(),
                    question: question,
                    answer: correctAnswer.endsWith('.') ? correctAnswer.slice(0, -1) : correctAnswer,
                    options: [
                        MCQArray[i + 2]?.endsWith('.') ? MCQArray[i + 2].slice(0, -1) : MCQArray[i + 2],
                        MCQArray[i + 3]?.endsWith('.') ? MCQArray[i + 3].slice(0, -1) : MCQArray[i + 3],
                        MCQArray[i + 4]?.endsWith('.') ? MCQArray[i + 4].slice(0, -1) : MCQArray[i + 4]]
                }]))
            }

            // Save Q and As
            const qAndAs = await generateQuestionsAndAnswers("Create at least five questions and answers from the following paragraph. '" +
                `${summarizedPara}` +
                "' The answer should be 5 words or less. Put a line break between the question and answer. If possible,the exact text of the answer should be found in the paragraph." +
                "Do not use any numbering, lettering or dashes in front of the question and answers in the responses. Start the response with the first question.");

            // @ts-ignore
            console.log(qAndAs.data.choices[0].message.content)

            // @ts-ignore
            const QandAsArray = qAndAs.data.choices[0].message.content.split("\n").map((item: string) => item.trim()).filter((item: string) => item !== "");

            for (let i = 0; i < QandAsArray.length; i += 2) {

                const question = QandAsArray[i];
                const correctAnswer = QandAsArray[i + 1].endsWith('.') ? QandAsArray[i + 1].slice(0, -1) : QandAsArray[i + 1];

                console.log("Question:", question);
                console.log("Correct Answer:", correctAnswer);

                appDispatch(saveQandAArray([{
                    _id: newObjectId(),
                    question: question,
                    answer: correctAnswer
                }]))
            }

            setParagraphText('')
        } catch (err) {
            console.error('Error generating Summary, MCQs and Q and As:', err);
        }
    };

    return (
        <>
            {
                (() => {
                    if (summaryIsLoading || QandAsAreLoading) {
                        return (
                            <Sheet sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <CircularProgress/>
                            </Sheet>
                        )
                    }
                })()
            }
            <Box
                sx={{
                    p: 2,
                    boxShadow: 'lg',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    whiteSpace: 'nowrap'
                }}
            >
                Paragraph Text To Process:&nbsp;&nbsp;
                <Input
                    value={paragraphText}
                    onChange={(event) => {
                        setParagraphText(event.target.value)
                    }}
                    sx={{
                        width: '100%'
                    }}
                    onKeyDown={
                        (event) => {
                            if (event.key === 'Enter') {
                                processParagraph()
                            }
                        }
                    }
                />
                <Button
                    sx={{
                        marginLeft: '10px'
                    }}
                    onClick={
                        () => processParagraph()
                    }
                >
                    <Typography
                        sx={{
                            color: 'white',
                        }}
                    >
                        Go
                    </Typography>
                </Button>
            </Box>
            {
                (() => {
                    if (showSnackbarMsg) {
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color="warning"
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={showSnackbarMsg}
                                onClose={() => {
                                    setShowSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {snackbarMsg}
                            </Snackbar>
                        )
                    }
                })()
            }
        </>
    )
}

export default ParseAndImportPDF