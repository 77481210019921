import React, {FC, useEffect} from 'react';
import Typography from '@mui/joy/Typography';
import {useCountdown} from 'usehooks-ts';
import {RootState} from "src/app/store";
import {useAppSelector, useAppDispatch} from "src/app/hooks";
import {useSpellingMgr} from "src/hooks/useSpellingMgr";
import {ModTypes} from "src/utils/constants";
import {IModWord} from "@backend/mongoose.gen";
import {useChapterMgr} from "src/hooks/useChapterMgr";
import {QuestionAndAnswer} from "src/slices/chapterSlice";
import {setAutoSubmit} from "src/slices/wordsSlice";

export const Footer: FC = () => {
    const type = useAppSelector((state: RootState) => state.pages.assessmentPageType);

    const [count, {resetCountdown, stopCountdown, startCountdown}] =
        useCountdown({
            countStart: 45,
            intervalMs: 1000,
        })

    let itemHasBeenPlayedOrRead: boolean = false;
    let indexValue: number = 0
    let items: Array<IModWord | QuestionAndAnswer> = [];
    let submitAction: () => void

    const appDispatch = useAppDispatch();

    const extractedQsandAs = useAppSelector((state: RootState) => state.chapter.extractedQsandAs);
    const currentQuestionIndex = useAppSelector((state: RootState) => state.chapter.currentQuestionIndex);
    const questionHasBeenRead = useAppSelector((state: RootState) => state.chapter.questionHasBeenRead);

    const words = useAppSelector((state: RootState) => state.words.words);
    const currentIndex = useAppSelector((state: RootState) => state.words.currentIndex);
    const wordHasBeenPlayed = useAppSelector((state: RootState) => state.words.wordHasBeenPlayed);

    const {submitAnswer} = useChapterMgr();
    const {submitSpelling} = useSpellingMgr();

    if (type === ModTypes.chapter || type === ModTypes.book) {
        items = extractedQsandAs
        itemHasBeenPlayedOrRead = questionHasBeenRead
        indexValue = currentQuestionIndex
        submitAction = submitAnswer
    } else if (type === ModTypes.spelling) {
        items = words
        itemHasBeenPlayedOrRead = wordHasBeenPlayed
        indexValue = currentIndex
        submitAction = submitSpelling
    }

    const pauseCountdown = useAppSelector((state: RootState) => state.words.pauseCountdown);

    useEffect(() => {
            if (itemHasBeenPlayedOrRead) {
                startCountdown()
            } else {
                resetCountdown()
            }
            // eslint-disable-next-line
        }, [itemHasBeenPlayedOrRead]
    )

    useEffect(() => {
            if (pauseCountdown) {
                stopCountdown()
            } else {
                startCountdown()
            }
            // eslint-disable-next-line
        }, [pauseCountdown]
    )

    useEffect(() => {
        if (count === 0) {
            if (type === ModTypes.spelling) {
                appDispatch(setAutoSubmit(true));
            }
            submitAction()
        }
        // eslint-disable-next-line
    }, [count])

    return (
        <>
            <div>
                <Typography level="h1" fontWeight="lg" fontSize="xl" padding="10px" bgcolor="blue"
                            sx={{
                                color: 'white',
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: 'center',
                                width: "100%",
                                boxSizing: "border-box",
                                height: '5vh'
                            }}>
                    <Typography>
                        Auto-submitting in {count} seconds
                    </Typography>
                    <Typography>
                        {indexValue + 1 < items.length ? indexValue + 1 : items.length} of {items.length}
                    </Typography>
                </Typography>
            </div>
        </>
    )
}