import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import Typography from "@mui/joy/Typography";
import {Box, Button, Chip, ColorPaletteProp, Divider, Input, Sheet, Snackbar, Table} from "@mui/joy";
import {saveSpellingMod} from "src/utils/api-service";
import {Header} from "src/components/Header";
import {IMod, IModWord} from "@backend/mongoose.gen";
import {newObjectId, setInputColor} from "src/utils/functions";
import {useSpellingMgr, WordsImportError} from "src/hooks/useSpellingMgr";
import {Paths, TextInputColor} from "src/utils/constants";
import {ImportOrgModHeader, OrgModSchema} from "src/components/ImportOrgModHeader";
import {z} from "zod";
import {ModTypes} from "src/utils/constants";
import ObjectID from "bson-objectid";
import {useNavigate} from "react-router-dom";

export const ImportWords: React.FC = () => {
    type NewWord = {
        word: string;
        usage: string;
        misspelling_1: string;
        misspelling_2: string;
        misspelling_3: string;
        alternate_spelling: string;
    };

    const [importComplete, setImportComplete] = useState(false)
    const [saveAllInfoClicked, setSaveAllInfoClicked] = useState(false)

    const [showImportMsg, setShowImportMsg] = React.useState<boolean>(false);
    const [snackBarMsgColor, setSnackBarMsgColor] = React.useState<string>("neutral");
    const [importMsg, setImportMsg] = React.useState<string>('');

    const [words, setWords] = React.useState<NewWord[]>([]);
    const [word, setWord] = React.useState<string>('');
    const [usage, setUsage] = React.useState<string>('')
    const [misspelling_1, setMisspelling_1] = React.useState<string>('')
    const [misspelling_2, setMisspelling_2] = React.useState<string>('')
    const [misspelling_3, setMisspelling_3] = React.useState<string>('')
    const [alternate_spelling, setAlternateSpelling] = React.useState<string>('')
    const [wordInputColor, setWordInputColor] = useState(TextInputColor.NEUTRAL)
    const [usageInputColor, setUsageInputColor] = useState(TextInputColor.NEUTRAL)
    const [misspelling_1InputColor, setMisspelling_1InputColor] = useState(TextInputColor.NEUTRAL)
    const [misspelling_2InputColor, setMisspelling_2InputColor] = useState(TextInputColor.NEUTRAL)
    const [misspelling_3InputColor, setMisspelling_3InputColor] = useState(TextInputColor.NEUTRAL)

    const refInputToFocus = useRef<HTMLInputElement | null>(null);
    const refWord = useRef<HTMLInputElement | null>(null);
    const refUsage = useRef<HTMLInputElement | null>(null);
    const refMisspelling_1 = useRef<HTMLInputElement | null>(null);
    const refMisspelling_2 = useRef<HTMLInputElement | null>(null);
    const refMisspelling_3 = useRef<HTMLInputElement | null>(null);

    const {importIsValid} = useSpellingMgr();

    const navigate = useNavigate();

    const successMsg: string = "Spelling Words have been imported successfully."

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === 'development')
            return

        if (prompt("Do the needful:") !== process.env.REACT_APP_KEY) {
            navigate(Paths.Home)
        }
    }, [])

    const wordsImported = () => {
        setImportMsg(successMsg)
        setSnackBarMsgColor('success')
        setShowImportMsg(true)
        setWords([]);
        setImportComplete(true)
    };

    const importFailure = (error: string) => {
        setImportMsg(`Error occurred importing words ... ${error}`)
        setSnackBarMsgColor('danger')
        setShowImportMsg(true)
    };

    const saveIndividualWordInfo = () => {
        let setInputColors: Array<Dispatch<SetStateAction<TextInputColor>>> = []

        refInputToFocus.current = null

        if (misspelling_3.trim() === '') {
            refInputToFocus.current = refMisspelling_3.current
            setInputColors.push(setMisspelling_3InputColor)
        }

        if (misspelling_2.trim() === '') {
            refInputToFocus.current = refMisspelling_2.current
            setInputColors.push(setMisspelling_2InputColor)
        }

        if (misspelling_1.trim() === '') {
            refInputToFocus.current = refMisspelling_1.current
            setInputColors.push(setMisspelling_1InputColor)
        }

        if (usage.trim() === '') {
            refInputToFocus.current = refUsage.current
            setInputColors.push(setUsageInputColor)
        }

        if (word.trim().length < 2) {
            setImportMsg('Word to import should be at least 2 characters long.')
            setShowImportMsg(true)
            refInputToFocus.current = refWord.current
            setInputColors.push(setWordInputColor)
        }

        if (refInputToFocus.current) {
            refInputToFocus.current.focus();
            setInputColors.forEach((sic) => {
                sic(TextInputColor.DANGER)
            })
        } else {
            let importValidationMsg = importIsValid(word.trim(), usage.trim(), [misspelling_1.trim(), misspelling_2.trim(), misspelling_3.trim()], words.map(({word}) => word))

            if (importValidationMsg !== WordsImportError.NONE) {
                setImportMsg(importValidationMsg)
                setShowImportMsg(true)
                return
            }

            setWords(prevWords => [...prevWords, {
                word: word.toLowerCase().trim(),
                usage: usage.trim(),
                misspelling_1: misspelling_1.toLowerCase().trim(),
                misspelling_2: misspelling_2.toLowerCase().trim(),
                misspelling_3: misspelling_3.toLowerCase().trim(),
                alternate_spelling: alternate_spelling.toLowerCase().trim()
            }]);
            setWord('')
            setUsage('')
            setMisspelling_1('')
            setMisspelling_2('')
            setMisspelling_3('')
            setAlternateSpelling('')
            refWord.current?.focus();
            setImportMsg("Word Info has been added.")
            setSnackBarMsgColor('success')
            setShowImportMsg(true)
        }
    };

    const deleteWordInfo = (indexToDelete: number) => {
        const updatedArray = [...words];
        updatedArray.splice(indexToDelete, 1);
        setWords(updatedArray);
        refWord.current?.focus()
    };

    const saveOrgModInfo = (orgModeInfo: z.infer<typeof OrgModSchema>) => {
        // If we are here, we know that we have a valid orgModeInfo
        let errorImportMsg = ''

        if (words.length === 0) {
            errorImportMsg = 'Please add some words to import.'
        }

        if (words.length < 5) {
            errorImportMsg = 'There must be at least 5 words to import.'
        }

        if (errorImportMsg !== '') {
            setImportMsg(errorImportMsg)
            setShowImportMsg(true)
            refWord.current?.focus()
            return
        }

        // Save Info in DB
        let infoToSave: IMod = {
            // @ts-ignore
            _id: ObjectID(newObjectId()),
            available: true,
            academic_year: orgModeInfo.academicYear,
            desc: orgModeInfo.desc,
            grade: parseInt(orgModeInfo.grade),
            org: orgModeInfo.org,
            serial_no: 4,
            subject: orgModeInfo.subject,
            title: orgModeInfo.title,
            type: ModTypes.spelling,
            words: words.map((word, index) => {
                const modWord: IModWord = {
                    _id: index + 1,
                    word: word.word,
                    usage: word.usage,
                    misspellings: [word.misspelling_1, word.misspelling_2, word.misspelling_3],
                    alternate_spelling: word.alternate_spelling
                };
                return modWord;
            })
        }

        saveSpellingMod(infoToSave, wordsImported, importFailure)
        setImportComplete(true)
        return true
    }

    return (
        <>
            <Header/>
            <ImportOrgModHeader
                type={ModTypes.spelling}
                importComplete={importComplete}
                saveAllInfoClicked={saveAllInfoClicked}
                saveInfo={(orgModeInfo: z.infer<typeof OrgModSchema>) => saveOrgModInfo(orgModeInfo)}
                existingChapter={null}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Divider
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                    <Chip variant="soft" color="primary" size="lg">
                        Words
                    </Chip>
                </Divider>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: 'repeat(3, 1fr)',
                        gap: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}>
                    <Input color={wordInputColor as ColorPaletteProp}
                           placeholder='Word *'
                           value={word}
                           slotProps={{
                               input: {
                                   ref: refWord
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setWordInputColor)
                               setWord(event.target.value)
                           }}/>
                    <Input color={usageInputColor as ColorPaletteProp} placeholder='Usage *' value={usage}
                           slotProps={{
                               input: {
                                   ref: refUsage
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setUsageInputColor)
                               setUsage(event.target.value)
                           }}/>
                    <Input color={misspelling_1InputColor as ColorPaletteProp} placeholder='Misspelling 1 *'
                           value={misspelling_1}
                           slotProps={{
                               input: {
                                   ref: refMisspelling_1
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setMisspelling_1InputColor)
                               setMisspelling_1(event.target.value)
                           }}/>
                    <Input color={misspelling_2InputColor as ColorPaletteProp} placeholder='Misspelling 2 *'
                           value={misspelling_2}
                           slotProps={{
                               input: {
                                   ref: refMisspelling_2
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setMisspelling_2InputColor)
                               setMisspelling_2(event.target.value)
                           }}/>
                    <Input color={misspelling_3InputColor as ColorPaletteProp} placeholder='Misspelling 3 *'
                           value={misspelling_3}
                           slotProps={{
                               input: {
                                   ref: refMisspelling_3
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setMisspelling_3InputColor)
                               setMisspelling_3(event.target.value)
                           }}/>
                    <Input placeholder='Alternate Spelling' value={alternate_spelling}
                           onChange={(event) => setAlternateSpelling(event.target.value)}/>
                </Box>
                <Button size="lg"
                        sx={{
                            marginTop: "10px"
                        }}
                        onClick={
                            () => saveIndividualWordInfo()
                        }
                >
                    <Typography fontSize="large">Save Individual Word Info</Typography>
                </Button>
                <div>
                    <Sheet sx={{height: 300, overflow: 'auto', marginLeft: '20px', marginRight: '20px'}}>
                        <Table
                            aria-label="table with sticky header"
                            stickyHeader
                            stickyFooter
                        >
                            <thead>
                                <tr>
                                    <th>Word</th>
                                    <th>Usage</th>
                                    <th>Misspelling 1</th>
                                    <th>Misspelling 2</th>
                                    <th>Misspelling 3</th>
                                    <th>Alternate Spelling</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {words.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.word}</td>
                                        <td>{row.usage}</td>
                                        <td>{row.misspelling_1}</td>
                                        <td>{row.misspelling_2}</td>
                                        <td>{row.misspelling_3}</td>
                                        <td>{row.alternate_spelling}</td>
                                        <td>
                                            <Box sx={{display: 'flex', gap: 1}}>
                                                <Button size="sm" variant="soft" color="danger"
                                                        onClick={
                                                            () => deleteWordInfo(index)
                                                        }>
                                                    Delete
                                                </Button>
                                            </Box>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan={7} style={{textAlign: 'center'}}>
                                    Count: {words.length}
                                </td>
                            </tr>
                            </tfoot>
                        </Table>
                    </Sheet>
                </div>
                <Divider
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                >
                </Divider>
                <Button
                    color='success'
                    size="lg"
                    sx={{
                        marginTop: "10px"
                    }}
                    onClick={
                        () => {
                            setImportComplete(false)
                            setSaveAllInfoClicked(true)
                        }
                    }
                >
                    <Typography fontSize="large">Save All Info to DB</Typography>
                </Button>
            </Box>

            {
                (() => {
                    if (showImportMsg) return (
                        <Snackbar
                            size="lg"
                            variant="solid"
                            color={snackBarMsgColor as ColorPaletteProp}
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            autoHideDuration={4000}
                            open={true}
                            onClose={() => {
                                setImportMsg('')
                                setShowImportMsg(false)
                                setSaveAllInfoClicked(false)
                            }}
                            sx={{
                                justifyContent: 'center',
                                padding: '5px',
                                whiteSpace: 'pre-line'
                            }}
                        >
                            {importMsg}
                        </Snackbar>
                    )
                })()
            }
        </>
    )
}

export default ImportWords