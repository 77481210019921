import {configureStore} from '@reduxjs/toolkit'
import chapterReducer from 'src/slices/chapterSlice'
import pagesReducer from 'src/slices/pageSlice'
import wordsReducer from 'src/slices/wordsSlice'
import spellingAssessmentReducer from 'src/slices/spellingAssessmentSlice'
import chapterAssessmentReducer from "src/slices/chapterAssessmentSlice"
import settingsReducer from "src/slices/settingsSlice"
import sharedReducer from "src/slices/sharedSlice"
import bookReducer from "src/slices/bookSlice"
import {modsAPI} from 'src/utils/api-service'
import {openAiApi} from 'src/utils/api-service'

export const store = configureStore({
    reducer: {
        book: bookReducer,
        chapter: chapterReducer,
        chapterAssessment: chapterAssessmentReducer,
        pages: pagesReducer,
        settings: settingsReducer,
        words: wordsReducer,
        spellingAssessment: spellingAssessmentReducer,
        shared: sharedReducer,
        [modsAPI.reducerPath]: modsAPI.reducer,
        [openAiApi.reducerPath]: openAiApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['book/setCurrentBook', 'book/saveOrgModHeaderInfoInStore'],
                ignoredPaths: ['book.currentBook._id'],
            }
        }).concat(modsAPI.middleware).concat(openAiApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch