import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IModWord} from "@backend/mongoose.gen";
import shuffle from "lodash/shuffle";
import {localStorageKeys} from "src/utils/constants";
import DarkTrafficLight from "src/images/dark_traffic_light.png"

export enum VoiceGender {
    FEMALE = 'female',
    MALE = 'male'
}

export interface WordsState {
    autoSubmit: boolean,
    currentIndex: number,
    pauseCountdown: boolean,
    title: string,
    retakeOfMisspelledAndSkippedWords: boolean,
    voiceGender: VoiceGender,
    words: IModWord[],
    wordsFromDB: IModWord[],
    wordHasBeenPlayed: boolean,
    wordCount: number,
    wordSpelling: string,
    assessmentCompletedBeforeStudying: boolean,
    spellingID: string,
    spellingStudyID: string  // This is the MongoDB ObjectID returned when the '/study/spelling/start' API call is made,
    spellingTestID: string,   // This is the MongoDB ObjectID returned when the '/test/spelling/start' API call is made
    currentTrafficLight: string
}

const initialState: WordsState = {
    autoSubmit: false,
    retakeOfMisspelledAndSkippedWords: false,
    currentIndex: 0,
    pauseCountdown: false,
    title: "",
    voiceGender: (() => {
        if (localStorage.getItem(localStorageKeys.VoiceGender)) {
            return localStorage.getItem(localStorageKeys.VoiceGender) as VoiceGender
        }

        return Math.round(Math.random()) === 0 ? VoiceGender.FEMALE : VoiceGender.MALE
    })(),
    words: [],
    wordsFromDB: [],
    wordHasBeenPlayed: false,
    wordCount: 0,
    wordSpelling: "",
    assessmentCompletedBeforeStudying: false,
    spellingID: "",
    spellingStudyID: "",
    spellingTestID: "",
    currentTrafficLight: DarkTrafficLight
}

export const wordsSlice = createSlice({
    name: 'words',
    initialState,
    reducers: {
        pauseCountdown(state) {
            state.pauseCountdown = true
        },
        resumeCountdown(state) {
            state.pauseCountdown = false
        },
        saveMisspelledAndSkippedWordsInfo(state, action: PayloadAction<{ words: IModWord[] }>) {
            state.retakeOfMisspelledAndSkippedWords = true;
            state.words = shuffle(action.payload.words);
        },
        saveSelectedSpellingInfo(state, action: PayloadAction<{
            spellingID: string,
            title: string,
            words: IModWord[]
        }>) {
            state.spellingID = action.payload.spellingID;
            state.retakeOfMisspelledAndSkippedWords = false;
            state.title = action.payload.title;
            state.words = shuffle(action.payload.words);
        },
        setWordHasBeenPlayed(state, action: PayloadAction<boolean>) {
            state.wordHasBeenPlayed = action.payload;
        },
        incrementCurrentIndex(state) {
            state.currentIndex++;
        },
        resetCurrentIndex(state) {
            state.currentIndex = 0
        },
        saveWordsFromDB(state, action: PayloadAction<IModWord[]>) {
            state.wordsFromDB = action.payload
        },
        updateWordSpelling(state, action: PayloadAction<string>) {
            state.wordSpelling = action.payload
        },
        updateVoiceGender(state, action: PayloadAction<VoiceGender>) {
            state.voiceGender = action.payload
            localStorage.setItem(localStorageKeys.VoiceGender, action.payload)
        },
        setAutoSubmit(state, action: PayloadAction<boolean>) {
            state.autoSubmit = action.payload
        },
        setAssessmentCompletedBeforeStudying(state, action: PayloadAction<boolean>) {
            state.assessmentCompletedBeforeStudying = action.payload
        },
        saveSpellingStudyID(state, action: PayloadAction<string>) {
            state.spellingStudyID = action.payload
        },
        saveSpellingTestID(state, action: PayloadAction<string>) {
            state.spellingTestID = action.payload
        },
        setCurrentTrafficLight(state, action: PayloadAction<string>) {
            state.currentTrafficLight = action.payload
        }
    },
});

export const {
    resetCurrentIndex,
    saveMisspelledAndSkippedWordsInfo,
    saveSelectedSpellingInfo,
    setWordHasBeenPlayed,
    incrementCurrentIndex,
    updateWordSpelling,
    updateVoiceGender,
    pauseCountdown,
    resumeCountdown,
    setAutoSubmit,
    setAssessmentCompletedBeforeStudying,
    saveSpellingStudyID,
    saveSpellingTestID,
    setCurrentTrafficLight
} = wordsSlice.actions;
export default wordsSlice.reducer;