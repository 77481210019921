import {Box} from "@mui/joy";

export const resizeButtonSX = () => ({
    fontSize: "large",
    fontWeight: "xl",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "fit-content",
    whiteSpace: "nowrap",
})

export const wordListPadding = () => {
    // This box is used to take up space above and below the Word List so that it's rows don't have a large gap.
    return (
        <Box
            sx={{
                flex: 1
            }}
        />
    )
}

