import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import Typography from "@mui/joy/Typography";
import {Box, Button, Checkbox, ColorPaletteProp, FormLabel, Input, Snackbar} from "@mui/joy";
import {setInputColor} from "src/utils/functions";
import {ModTypes, TextInputColor} from "src/utils/constants";
import {z} from "zod";
import {ImportInfo} from "src/utils/interfaces";
import Link from "@mui/joy/Link";
import {useAppSelector, useAppDispatch} from "src/app/hooks";
import {RootState} from "src/app/store";
import {IBookModSchema} from "src/zodSchemas";
import {
    saveOrgModHeaderInfoInStore,
    setBookMadeUnavailableAutomatically
} from "src/slices/bookSlice";
import {updateBookModInfo} from "src/utils/api-service";
import _ from "lodash";
import ObjectID from "bson-objectid";

export const IOrgModInfoSchema = IBookModSchema.pick({
    _id: true,
    org: true,
    academic_year: true,
    title: true,
    subject: true,
    grade: true,
    desc: true,
    available: true
})

export const OrgModSchema = z.object(
    {
        org: z.string().trim().min(1),
        title: z.string().trim().min(1),
        subject: z.string().trim().min(1),
        grade: z.string().trim().min(1),
        academicYear: z.string().trim().min(1),
        desc: z.string(),
        available: z.boolean()
    }
)

interface ImportOrgModHeaderProps {
    type: string
}

interface CombinedProps extends ImportInfo, ImportOrgModHeaderProps {
}

export const ImportOrgModHeader: FC<CombinedProps> = ({
                                                          type,
                                                          saveAllInfoClicked
                                                      }) => {
    const [snackbarMsg, setSnackbarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [snackbarMsgColor, setSnackbarMsgColor] = useState("neutral")
    const [showBookMadeUnavailable, setShowBookMadeUnavailable] = useState(false)

    const [orgModeInfo, setOrgModeInfo] = useState<z.infer<typeof IOrgModInfoSchema>>(
        {
            org: '',
            academic_year: '24-25',
            title: '',
            subject: '',
            grade: 0,
            desc: '',
            available: false
        })

    const [academicYearInputColor, setAcademicYearInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [orgInputColor, setOrgInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [subjectInputColor, setSubjectInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [titleInputColor, setTitleInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const [gradeInputColor, setGradeInputColor] = useState<TextInputColor>(TextInputColor.NEUTRAL);
    const refAcademicYear = useRef<HTMLInputElement | null>(null);
    const refOrg = useRef<HTMLInputElement | null>(null);
    const refGrade = useRef<HTMLInputElement | null>(null);
    const refTitle = useRef<HTMLInputElement | null>(null);
    const refSubject = useRef<HTMLInputElement | null>(null);

    const appDispatch = useAppDispatch();

    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);
    const bookMadeUnavailableAutomatically = useAppSelector((state: RootState) => state.book.bookMadeUnavailableAutomatically);
    const chaptersThatNeedAvailabilitySaved = useAppSelector((state: RootState) => state.book.chaptersThatNeedAvailabilitySaved);

    const highlightInvalidField = (fieldName: string[] | undefined, inputElem: HTMLInputElement, setInputColorAction: Dispatch<SetStateAction<TextInputColor>>) => {
        if (fieldName !== undefined) {
            inputElem.focus()
            setInputColorAction(TextInputColor.DANGER)
            setSnackbarMsg(fieldName[0])
            setShowSnackbarMsg(true)
        }
    }

    useEffect(() => {
        refAcademicYear.current?.focus()

        if (type === ModTypes.book && currentBook._id) {
            setOrgModeInfo({
                _id: new ObjectID(currentBook._id.toString()),
                academic_year: currentBook.academic_year,
                available: currentBook.available,
                desc: currentBook.desc,
                grade: currentBook.grade!,
                org: currentBook.org,
                subject: currentBook.subject,
                title: currentBook.title
            })
        }
    }, []);

    useEffect(() => {
        if (saveAllInfoClicked) {
            // Note that we 'omit' grade when validating, which is initially typed as a z.string() rather than z.number(), which would require a default value for a non-optional key. This default value (assume it's 0) would hide the 'Grade' placeholder from being visible in the Input box.
            // Zod allows us to use the number validation we want on Grade by using 'extend' and 'coerce' on submission.
            const result = IBookModSchema.omit({grade: true}).extend({grade: z.coerce.number().min(4).max(12)}).safeParse(currentBook)

            if (!result.success) {
                highlightInvalidField(result.error.flatten().fieldErrors.title, refTitle.current!, setTitleInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.subject, refSubject.current!, setSubjectInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.grade, refGrade.current!, setGradeInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.org, refOrg.current!, setOrgInputColor)
                highlightInvalidField(result.error.flatten().fieldErrors.academic_year, refAcademicYear.current!, setAcademicYearInputColor)
                return
            }
        }
    }, [saveAllInfoClicked])

    useEffect(() => {
        const modeInfoIDResult = IOrgModInfoSchema.safeParse(orgModeInfo)

        // If all Chapters in a Book are unavailable, make the Book unavailable also.
        if (modeInfoIDResult.success && bookMadeUnavailableAutomatically) {
            setBookMadeUnavailableAutomatically(false)
            setShowBookMadeUnavailable(true)

            // Local Variable
            setOrgModeInfo(prevState => ({
                    ...prevState,
                    available: false
                })
            )

            // RTK Variable
            appDispatch(saveOrgModHeaderInfoInStore({
                ...orgModeInfo,
                available: false
            }))

            // Save in DB
            updateBookModInfo({
                ...orgModeInfo,
                available: false
            }, modInfoUpdated, updateFailure)
        }
    }, [bookMadeUnavailableAutomatically])

    useEffect(() => {
        if (!showSnackbarMsg && showBookMadeUnavailable) {
            setSnackbarMsg('Since all Chapters in the Book are unavailable, the Book has automatically been made unavailable also.')
            setShowBookMadeUnavailable(false)
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
        }
    }, [showSnackbarMsg]);

    const updateFailure = (error: string) => {
        setSnackbarMsg(`Error occurred importing Data ... ${error}`)
        setSnackbarMsgColor('danger')
        setShowSnackbarMsg(true)
    };

    const modInfoUpdated = (_: any) => {
        setSnackbarMsg('The Mod Info has been updated in the DB.')
        setSnackbarMsgColor('success')
        setShowSnackbarMsg(true)
    };

    const ChapterAndBookAvailabilityIsValid = () => {
        if (orgModeInfo.available && currentBook.chapters.filter(chapter => chapter.available)!.length === 0) {
            setShowBookMadeUnavailable(false)
            setSnackbarMsg('In order for a Book to be available, there must be at least one available Chapter.')
            setSnackbarMsgColor('danger')
            setShowSnackbarMsg(true)
            setOrgModeInfo(prevState => ({
                    ...prevState,
                    available: false
                })
            )

            return false
        }

        return true
    }

    const upsertModInfo = () => {
        if (chaptersThatNeedAvailabilitySaved.length > 0) {
            setSnackbarMsg('Please save all modified Chapters before updating the Mod.')
            setSnackbarMsgColor('neutral')
            setShowSnackbarMsg(true)
            return
        }

        const modeInfoIDResult = IOrgModInfoSchema.safeParse(orgModeInfo)

        if (modeInfoIDResult.success) {
            if (currentBook._id === undefined) {
                // A New Book is being saved.
                if (ChapterAndBookAvailabilityIsValid()) {
                    appDispatch(saveOrgModHeaderInfoInStore(orgModeInfo))
                    setSnackbarMsg("The Mod Info has been saved locally.")
                    setSnackbarMsgColor("success")
                    setShowSnackbarMsg(true)
                }
            } else {
                // A Book is being edited.
                if (_.isMatch(currentBook, orgModeInfo)) {
                    setSnackbarMsg("No changes in Mod Info found.")
                    setSnackbarMsgColor("danger")
                    setShowSnackbarMsg(true)
                } else if (ChapterAndBookAvailabilityIsValid()) {
                    setOrgModeInfo(prevState => ({
                            ...prevState
                        })
                    )
                    appDispatch(saveOrgModHeaderInfoInStore({
                        ...orgModeInfo
                    }))

                    updateBookModInfo({
                        ...orgModeInfo
                    }, modInfoUpdated, updateFailure)
                }
            }
        } else {
            // The order is from bottom to type for the fields so that the input focus will be set to the first invalid field.
            setSnackbarMsgColor("danger")
            highlightInvalidField(modeInfoIDResult.error.flatten().fieldErrors.title, refTitle.current!, setTitleInputColor)
            highlightInvalidField(modeInfoIDResult.error.flatten().fieldErrors.subject, refSubject.current!, setSubjectInputColor)
            highlightInvalidField(modeInfoIDResult.error.flatten().fieldErrors.grade, refGrade.current!, setGradeInputColor)
            highlightInvalidField(modeInfoIDResult.error.flatten().fieldErrors.org, refOrg.current!, setOrgInputColor)
            highlightInvalidField(modeInfoIDResult.error.flatten().fieldErrors.academic_year, refAcademicYear.current!, setAcademicYearInputColor)
        }
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="x-large"
                            sx={{paddingBottom: "5px"}}>
                    <u>Import {type.charAt(0).toUpperCase() + type.slice(1)}</u>
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gap: .5,
                        whiteSpace: 'nowrap',
                    }}
                >
                    <FormLabel>Academic Year *</FormLabel>
                    <Input color={academicYearInputColor as ColorPaletteProp}
                           value={orgModeInfo.academic_year} slotProps={{
                        input: {
                            ref: refAcademicYear
                        },
                    }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setAcademicYearInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       academic_year: event.target.value
                                   })
                               )
                           }
                           }
                    />
                    <FormLabel>Organization *</FormLabel>
                    <Input color={orgInputColor as ColorPaletteProp}
                           value={orgModeInfo.org}
                           slotProps={{
                               input: {
                                   ref: refOrg
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setOrgInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       org: event.target.value
                                   })
                               )
                           }}
                    />
                    <FormLabel>Grade *</FormLabel>
                    <Input color={gradeInputColor as ColorPaletteProp}
                           value={orgModeInfo.grade}
                           slotProps={{
                               input: {
                                   type: 'number',
                                   ref: refGrade
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setGradeInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       grade: parseInt(event.target.value)
                                   })
                               )
                           }}
                    />
                    <FormLabel>Subject *</FormLabel>
                    <Input color={subjectInputColor as ColorPaletteProp}
                           value={orgModeInfo.subject}
                           slotProps={{
                               input: {
                                   ref: refSubject
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setSubjectInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       subject: event.target.value
                                   })
                               )
                           }}
                    />
                    <FormLabel>Mod Title *</FormLabel>
                    <Input color={titleInputColor as ColorPaletteProp}
                           value={orgModeInfo.title}
                           slotProps={{
                               input: {
                                   ref: refTitle
                               },
                           }}
                           onChange={(event) => {
                               setInputColor(event.target.value, setTitleInputColor)
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       title: event.target.value
                                   })
                               )
                           }
                           }
                    />
                    <FormLabel>Description</FormLabel>
                    <Input value={orgModeInfo.desc || ''}
                           onChange={(event) =>
                               setOrgModeInfo(prevState => ({
                                       ...prevState,
                                       desc: event.target.value
                                   })
                               )
                           }
                    />
                    <div style={{display: 'flex', whiteSpace: 'nowrap', alignItems: 'center'}}>
                        <FormLabel>Mod Available to Study?</FormLabel>
                        &nbsp;&nbsp;
                        <Checkbox checked={orgModeInfo.available} onClick={() =>
                            setOrgModeInfo(prevState => ({
                                    ...prevState,
                                    available: !prevState.available
                                })
                            )
                        }
                        />
                    </div>
                    <Button
                        onClick={
                            () => upsertModInfo()
                        }
                    >
                        <Typography
                            sx={{
                                color: 'white'
                            }}
                        >
                            {currentBook._id === undefined ? 'Save Mod Info' : 'Update Mod Info in DB'}
                        </Typography>
                    </Button>
                    <Link
                        underline='always'
                        onClick={async () => {
                            await navigator.clipboard.writeText('Summarize the essential points in the following paragraph by removing the extraneous text and making the paragraph more concise. The output should be in paragraph format. \n\n')
                        }}>
                        Copy 'Summarize Paragraph'
                    </Link>
                    <Link
                        underline='always'
                        onClick={async () => {
                            await navigator.clipboard.writeText('Create multiple choice questions and answers from the following paragraph.  The first answer shown should be the correct one. Do not prefix the answers with "A)", "B)", etc.).  For each created question, show 4 answers. \n\n')
                        }}>
                        Copy 'Create MCQ'
                    </Link>
                    <div>
                        <Link
                            underline='always'
                            onClick={async () => {
                                await navigator.clipboard.writeText('Create questions and answers from the following paragraph.  Only show the question and answer. The answer should be 4 words or less and the exact text of the answer should be found in the paragraph. \n\n')
                            }}
                        >
                            Copy 'Create Q & A'
                        </Link>
                    </div>
                </Box>
            </Box>
            <Snackbar
                size="lg"
                variant="solid"
                color={snackbarMsgColor as ColorPaletteProp}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={3000}
                open={showSnackbarMsg}
                onClose={() => {
                    setShowSnackbarMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                    whiteSpace: 'pre-line'
                }}
            >
                {snackbarMsg}
            </Snackbar>
        </>
    )
}