import React, {useEffect} from 'react';
import {Header} from 'src/components/Header';
import {AssessmentResultFooter} from "src/components/Shared/AssessmentResultFooter";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {Box, List, ListItem} from '@mui/joy';
import Typography from "@mui/joy/Typography";
import {resetSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {Page, update} from "src/slices/pageSlice";
import Button from "@mui/joy/Button";
import {resizeButtonSX, wordListPadding} from "src/utils/ui";
import {useSpellingMgr} from "src/hooks/useSpellingMgr";
import {SpellingResult} from "src/hooks/useSpellingMgr";
import {ModTypes} from "src/utils/constants";
import {completeSpellingTest} from "src/utils/api-service";

export const SpellingAssessmentResult: React.FC = () => {
    const title = useAppSelector((state: RootState) => state.words.retakeOfMisspelledAndSkippedWords ? `Misspelled and Skipped Words of ${state.words.title}` : state.words.title);
    const results = useAppSelector((state: RootState) => state.spellingAssessment.data);
    const spellingTestID = useAppSelector((state: RootState) => state.words.spellingTestID);

    const appDispatch = useAppDispatch();
    const {assessmentIsValid, getSpellingResult, getSpellingResultCounts} = useSpellingMgr();
    const resultCounts = getSpellingResultCounts(results)

    useEffect(() => {
        completeSpellingTest(spellingTestID,
            (response) => {
                if (response.data.message === 'Spelling Test Record Not Found') {
                    console.log(response.data.message)
                }
            },
            (error: any) => console.error("Error:", error)
        )
    }, []);

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: "center",
                    height: '10vh'
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="xxx-large">
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: 'center',
                    height: '7vh'
                }}
            >
                <Typography level="h1" fontWeight="lg" fontSize="xx-large">
                    Spelling Assessment Results
                </Typography>
            </Box>
            {
                (() => {
                    if (assessmentIsValid(results)) {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        width: '80%',
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {results.map((word, index) => (
                                        <div key={index}>
                                            <List
                                                size='sm'
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'sm',
                                                    fontSize: 'lg',
                                                    height: 'fit-content',
                                                }}
                                            >
                                                <ListItem>
                                                    {
                                                        (() => {
                                                            if (getSpellingResult(word) === SpellingResult.MISSPELLED) {
                                                                return <>
                                                                    <Typography
                                                                        color="danger">
                                                                        {word.misspelling}
                                                                    </Typography>
                                                                    <Typography
                                                                        color="success">
                                                                        ({word.word})
                                                                    </Typography>
                                                                </>;
                                                            }
                                                            if (getSpellingResult(word) === SpellingResult.SKIPPED) {
                                                                return <>
                                                                    <Typography
                                                                        color="warning">
                                                                        skipped
                                                                    </Typography>
                                                                    <Typography
                                                                        color="success">
                                                                        ({word.word})
                                                                    </Typography>
                                                                </>;
                                                            }
                                                            return <Typography color="success">
                                                                {word.word}
                                                            </Typography>;
                                                        })()
                                                    }
                                                </ListItem>
                                            </List>
                                        </div>
                                    ))}
                                </Box>
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        height: '15vh'
                                    }}
                                >
                                    {
                                        (() => {
                                            if (resultCounts[SpellingResult.MISSPELLED] === 0 && resultCounts[SpellingResult.SKIPPED] === 0) {
                                                return (
                                                    <Button
                                                        variant="solid" color="primary"
                                                        sx={resizeButtonSX()}
                                                        onClick={
                                                            () => {
                                                                appDispatch(update(Page.HOME))
                                                            }
                                                        }
                                                    >
                                                        Home
                                                    </Button>
                                                )
                                            }

                                            return (
                                                <Button
                                                    variant="solid" color="primary"
                                                    sx={resizeButtonSX()}
                                                    onClick={
                                                        () => {
                                                            appDispatch(update(Page.STUDY_WORDS))
                                                        }
                                                    }
                                                >
                                                    {(resultCounts.misspelled + resultCounts.skipped) === 1 ? 'Study Word' : 'Study Words'}
                                                </Button>
                                            )
                                        })()
                                    }
                                </Box>
                            </Box>
                        )
                    }

                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    color="danger"
                                    fontSize="x-large"
                                >
                                    Not enough words were spelled to complete the assessment.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    height: '15vh'
                                }}
                            >
                                <Button
                                    variant="solid" color="primary"
                                    sx={resizeButtonSX()}
                                    onClick={
                                        () => {
                                            appDispatch(resetSpellingAssessment())
                                            appDispatch(update(Page.SHOW_WORD))
                                        }
                                    }
                                >
                                    Restart the Assessment
                                </Button>
                            </Box>
                        </>
                    )
                })()
            }
            <AssessmentResultFooter correct={resultCounts[SpellingResult.CORRECT]}
                                    incorrect={resultCounts[SpellingResult.MISSPELLED]}
                                    skipped={resultCounts[SpellingResult.SKIPPED]}
                                    type={ModTypes.spelling}
            />
        </div>
    );
}