import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {
    incrementCurrentIndex,
    resetCurrentIndex,
    setWordHasBeenPlayed,
    setCurrentTrafficLight
} from "src/slices/wordsSlice";
import {ISpellingAssessment, saveSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {Page, update} from "src/slices/pageSlice";
import {useRef} from "react";
import {getDuration} from "src/utils/functions";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import GreenTrafficLight from "src/images/green_traffic_light.png"
import RedTrafficLight from "src/images/red_traffic_light.png"
import YellowTrafficLight from "src/images/yellow_traffic_light.png"

export const enum WordsImportError {
    NONE = '',
    WORD_IS_NOT_CONTAINED_IN_USAGE = 'Word is not contained in Usage\n',
    MISSPELLINGS_ARE_NOT_DIFFERENT_FROM_WORD = 'Misspellings are not different from Word\n',
    MISSPELLING_IS_REPEATED = 'Misspelling is repeated\n',
    WORD_HAS_BEEN_ADDED_PREVIOUSLY = 'Word has been added previously\n'
}

export const enum SpellingResult {
    CORRECT = 'correct',
    MISSPELLED = 'misspelled',
    SKIPPED = 'skipped'
}

export const useSpellingMgr = () => {
    const refStartDuration = useRef(new Date().getTime());
    const currentIndex = useAppSelector((state: RootState) => state.words.currentIndex);
    const words = useAppSelector((state: RootState) => state.words.words);
    const wordSpelling = useAppSelector((state: RootState) => state.words.wordSpelling);

    const currentWord = words[currentIndex]?.word ? words[currentIndex].word : ""
    const alternateSpellingOfCurrentWord = words[currentIndex]?.alternate_spelling ? words[currentIndex].alternate_spelling : ""

    const {playCorrectAudio, playWrongAudio} = useSharedMgr();

    const appDispatch = useAppDispatch();

    const incrementIndexOrGoToSpellingResult = () => {
        if ((currentIndex + 1) < words.length) {
            appDispatch(incrementCurrentIndex())
            appDispatch(setCurrentTrafficLight(DarkTrafficLight))
        } else {
            appDispatch(resetCurrentIndex())
            appDispatch(update(Page.SPELLING_RESULT))
        }
    }

    const wordIsSpelledCorrectly = (correctSpelling: string, userEnteredSpelling: string, alternateCorrectSpelling?: string): boolean => {
        let result: boolean

        result = correctSpelling.trim().toLowerCase() === userEnteredSpelling.trim().toLowerCase();

        if (!result && alternateCorrectSpelling) {
            result = alternateCorrectSpelling.trim().toLowerCase() === userEnteredSpelling.trim().toLowerCase();
        }

        return result
    }

    /**
     * Validates if the given word is valid for import based on its usage, misspellings, and previous additions.
     *
     * @param {string} word - the word to be validated
     * @param {string} usage - the usage context in which the word should be contained
     * @param {string[]} misspellings - list of potential misspellings for the word
     * @param {string[]} previouslyAddedWords - list of words previously added
     * @return {string} an error message indicating issues with the word import, if any
     */
    const importIsValid = (word: string, usage: string, misspellings: string[], previouslyAddedWords: string[]): string => {
        let errorString = ''
        let wordIsContainedInUsage: boolean
        let misspellingsAreDifferentFromWord: boolean
        let misspellingIsRepeated: boolean
        let wordHasBeenAddedPreviously: boolean

        const cleanedUsage = usage.replace(/[^a-zA-Z0-9\s]/g, '')

        wordIsContainedInUsage = cleanedUsage.toLowerCase().split(" ").includes(word.toLowerCase())

        if (!wordIsContainedInUsage) {
            errorString = WordsImportError.WORD_IS_NOT_CONTAINED_IN_USAGE
        }

        const lowerCaseMisspellings = misspellings.map((string) => string.toLowerCase());

        misspellingsAreDifferentFromWord = lowerCaseMisspellings.every((string) => string !== word.toLowerCase());

        if (!misspellingsAreDifferentFromWord) {
            errorString += WordsImportError.MISSPELLINGS_ARE_NOT_DIFFERENT_FROM_WORD
        }

        misspellingIsRepeated = new Set(misspellings.map(str => str.toLowerCase())).size !== misspellings.length;

        if (misspellingIsRepeated) {
            errorString += WordsImportError.MISSPELLING_IS_REPEATED
        }

        wordHasBeenAddedPreviously = previouslyAddedWords.includes(word)

        if (wordHasBeenAddedPreviously) {
            errorString += WordsImportError.WORD_HAS_BEEN_ADDED_PREVIOUSLY
        }

        return errorString
    }
    const submitSpelling = () => {
        appDispatch(setWordHasBeenPlayed(false))

        const currentWordText = alternateSpellingOfCurrentWord ? `${currentWord} / ${alternateSpellingOfCurrentWord}` : currentWord
        const spellingResult: ISpellingAssessment = {
            _id: words[currentIndex]._id,
            word: currentWordText,
            duration: getDuration(refStartDuration)
        }

        if (wordIsSpelledCorrectly(currentWord, wordSpelling, alternateSpellingOfCurrentWord)) {
            appDispatch(setCurrentTrafficLight(GreenTrafficLight))
            playCorrectAudio()
            appDispatch(saveSpellingAssessment(
                spellingResult
            ))
        } else {
            if (wordSpelling.trim() === '') {
                appDispatch(setCurrentTrafficLight(YellowTrafficLight))
            } else {
                appDispatch(setCurrentTrafficLight(RedTrafficLight))
            }

            playWrongAudio()
            spellingResult.misspelling = wordSpelling.trim()
            appDispatch(saveSpellingAssessment(
                spellingResult
            ))
        }
    }
    const getSpellingResult = (spellingResult: ISpellingAssessment): SpellingResult => {
        if (spellingResult.misspelling && spellingResult.misspelling.length > 0) {
            return SpellingResult.MISSPELLED;
        }

        if (spellingResult.misspelling !== undefined && spellingResult.misspelling.length === 0) {
            return SpellingResult.SKIPPED;
        }

        return SpellingResult.CORRECT;
    };

    const getSpellingResultCounts = (spellingResults: ISpellingAssessment[]): Record<SpellingResult, number> => {
        const resultCounts: Record<SpellingResult, number> = {
            [SpellingResult.CORRECT]: 0,
            [SpellingResult.MISSPELLED]: 0,
            [SpellingResult.SKIPPED]: 0,
        };

        spellingResults.forEach((result) => {
            const spellingResult = getSpellingResult(result);
            resultCounts[spellingResult]++;
        });

        return resultCounts;
    };

    const assessmentIsValid = (results: ISpellingAssessment[]) => {
        const countOfWordsWhereSpellingWasNotAttempted = results.filter(result => result.misspelling === "").length;
        return ((results.length - countOfWordsWhereSpellingWasNotAttempted) / results.length) > .74;
    };

    const replaceWordWithUnderscoreChars = (sentence: string, word: string): string => {
        const underscore = '_'.repeat(word.length + 2);
        return sentence.replace(new RegExp(word, 'gi'), underscore);
    };

    return {
        assessmentIsValid,
        getSpellingResult,
        getSpellingResultCounts,
        importIsValid,
        incrementIndexOrGoToSpellingResult,
        replaceWordWithUnderscoreChars,
        submitSpelling,
        wordIsSpelledCorrectly
    };
};
