import React from "react";
import {Box} from "@mui/joy";

interface BodyProps {
    children: React.ReactNode
    boxGap?: number
}

const Body: React.FC<BodyProps> = ({children, boxGap}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: 'center',
                gap: boxGap ? boxGap : 0
            }}
        >
            {children}
        </Box>
    );
};

export default Body;